import React, { ComponentType, useEffect } from "react";
import { Redirect, Route, RouteProps, Switch } from "react-router-dom";

import { getToken } from "common/helpers";
import { selectSubscriptions, selectUser } from "modules/me/slice";
import ACTIONS from "modules/rootActions";
import useAppDispatch from "hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";
import AppPage from "pages/AppPage";
import HomePage from "pages/Homepage";
import AboutUs from "pages/PublicPages/AboutUs";
import Announcements from "pages/PublicPages/Announcements";
import BlogEntry from "pages/PublicPages/BlogEntry";
import FrequentlyAskedQuestions from "pages/PublicPages/FrequentlyAskedQuestions";
import PrivacyPolicyPage from "pages/PublicPages/PrivacyPolicyPage";
import TermsAndConditions from "pages/PublicPages/TermsAndConditionsPage";
import Registration from "features/Registration";

// import Pricing from 'pages/PublicPages/Pricing';
// import SignIn from 'features/SignIn';

type PrivateRouteType = {
    component: ComponentType;
} & RouteProps;

const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteType) => {
    const user = useAppSelector(selectUser);
    const subscriptions = useAppSelector(selectSubscriptions);
    const token = getToken();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (token) {
            if (user === null) {
                dispatch({
                    type: ACTIONS.FETCH_USER_INFO,
                    payload: { accessToken: getToken() },
                });
            }
            if (subscriptions === null)
                dispatch({
                    type: ACTIONS.FETCH_USER_SUBSCRIPTIONS,
                    payload: { accessToken: getToken() },
                });
        }
    }, [token, dispatch, subscriptions, user]);

    return (
        <Route
            {...rest}
            render={(props) => {
                if (token) return <Component {...props} />;

                return <Redirect to="/" />;
            }}
        />
    );
};

const App = () => {
    return (
        <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/register" component={Registration} />
            {/* <Route exact path="/login" component={SignIn} /> */}

            {/* <Route exact path="/pricing" component={Pricing} /> */}
            <Route exact path="/about-us" component={AboutUs} />
            <Route exact path="/announcements" component={Announcements} />
            <Route exact path="/blog-entry" component={BlogEntry} />
            <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
            <Route
                exact
                path="/terms-and-conditions"
                component={TermsAndConditions}
            />
            <Route
                exact
                path="/frequently-asked-questions"
                component={FrequentlyAskedQuestions}
            />

            <PrivateRoute path="/app" component={AppPage} />
        </Switch>
    );
};

export default App;
