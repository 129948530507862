import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { browserHistory } from "app/history";
import { store } from "./app/store";
import "themes/tailwind.css";
import "themes/global.css";
import App from "./app";

const root = document.getElementById("root");

// @ts-ignore
ReactDOM.createRoot(root).render(
    <Provider store={store}>
        <ConnectedRouter history={browserHistory}>
            <App />
        </ConnectedRouter>
    </Provider>
);
