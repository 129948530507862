import classNames from "classnames";
import React from "react";
import { FieldError } from "react-hook-form";

interface IInputTextProps {
    type: string;
    name: string;
    placeholder: string;
    required: boolean;
    error: FieldError | undefined;
    register: any;
}

const CustomInput = (props: IInputTextProps) => {
    return (
        <div className="relative flex flex-col font-roboto text-black border-b-[1px] border-iknow-blue">
            <input
                type={props.type}
                name={props.name}
                placeholder=""
                className={classNames(
                    "peer [&>input]:text-[12px] [&>input]:md:text-[14px] [&>textarea]:!border-t-tranparent [&>input]:!border-t-tranparent relative flex h-[50px] w-full items-center !bg-transparent md:h-[60px] [&>input]:!self-end !outline-none",
                    {
                        "border-red":
                            props.error && Object.keys(props.error).length > 0,
                    }
                )}
                required={props.required}
                {...props.register(props.name)}
            />
            <label
                htmlFor={props.name}
                className="pointer-events-none absolute top-0 z-[10] text-[9px] text-black opacity-[.6] duration-200 md:top-0 peer-placeholder-shown:top-[18px] peer-placeholder-shown:md:top-[18px] peer-placeholder-shown:text-[14px] peer-placeholder-shown:md:text-[16px] peer-focus:top-0 peer-focus:text-[9px] peer-focus:md:top-0 peer-focus:md:text-[9px] text-medium"
            >
                {props.placeholder}
            </label>
        </div>
    );
};

export default CustomInput;
