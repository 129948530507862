import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import classNames from "classnames";

import iKnowLogoWhite from "assets/images/logo/iKnow_Logo_White.svg";
import { selectUser } from "modules/me/slice";
import useAppSelector from "hooks/useAppSelector";
import useLogout from "hooks/useLogout";

export const AuthNavigation = () => {
	const user = useAppSelector(selectUser);
	const [menuOpen, setMenuOpen] = useState(false);
	const { logout } = useLogout();

	const accountMenu = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const checkIfClickedOutside = (e: any) => {
			if (
				menuOpen &&
				accountMenu.current &&
				!accountMenu.current.contains(e.target)
			) {
				setMenuOpen(false);
			}
		};
		document.addEventListener("click", checkIfClickedOutside);
		return () => {
			document.removeEventListener("click", checkIfClickedOutside);
		};
	}, [menuOpen]);

	return (
		<div className="z-[99999] h-[120px] sticky top-0 flex flex-row px-[50px] content-center bg-slate-800 text-white">
			<a href="/" className="my-auto">
				<img src={iKnowLogoWhite} className="object-contain my-auto h-[75px]" />
			</a>
			<div className="my-auto ml-auto flex flex-row gap-[40px] font-bold">
				<a href="/app/dashboard" className="group transition duration-300">
					DASHBOARD
					<span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-1 bg-iknow-yellow"></span>
				</a>
				<button
					className="group transition duration-300 uppercase relative"
					onClick={() => setMenuOpen(true)}
				>
					{user?.firstName}
					<span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-1 bg-iknow-yellow"></span>
					<div
						ref={accountMenu}
						className={classNames(
							"absolute min-w-[200px] flex flex-col text-black bg-gray-200 rounded-[5px] py-[25px] right-0 after:bottom-0",
							{
								hidden: !menuOpen,
								block: menuOpen,
							}
						)}
					>
						<Link to="/app/account">
							<div className="py-[15px] w-full text-black hover:bg-iknow-yellow">
								MY ACCOUNT
							</div>
						</Link>
						<div
							className="py-[15px] w-full text-black hover:bg-iknow-yellow"
							onClick={() => logout()}
						>
							LOGOUT
						</div>
					</div>
				</button>
			</div>
		</div>
	);
};

export default AuthNavigation;