import axios from "axios";
import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select } from "redux-saga/effects";
import { handleAPIError } from "common/errorHandler";
import api, { authenticatedClient } from "app/apiClient";
import {
    selectError,
    setError,
    setLoginError,
    setPendingLogin,
    setPendingLogout,
    setRegisterStatus,
} from "./slice";
import { reset as resetMe } from "modules/me/slice";
import { removeToken, setToken } from "common/helpers";
import { push } from "connected-react-router";
import { RequestStatusEnum } from "common/types";

export const SAGA_ACTIONS = {
    LOGIN_ACCOUNT: "LOGIN_ACCOUNT",
    LOGOUT_ACCOUNT: "LOGOUT_ACCOUNT",
    REGISTER_ACCOUNT: "REGISTER_ACCOUNT",
};

type LoginAccountPayload = {
    email: string;
    password: string;
};

export function* loginAccount(action: PayloadAction<LoginAccountPayload>): any {
    try {
        yield put(setPendingLogin(true));
        const { data } = yield call(() => api.post("/login", action.payload));
        if (data.jwt) {
            setToken(data.jwt);
            yield put(push("/app/dashboard"));
        }
    } catch (err: any) {
        if (axios.isAxiosError(err) && err.response) {
            handleAPIError(err.response);
            yield put(
                setLoginError(
                    err.response.data?.errorCode?.message ||
                        err.response.data?.errors ||
                        err.response.data?.error
                )
            );
        }
    } finally {
        yield put(setPendingLogin(false));
    }
}

export function* logoutAccount() {
    const api = authenticatedClient();
    try {
        yield call(() => api.post("/logout"));
        yield put(setPendingLogout(true));
        removeToken();
        yield put(push({ pathname: "/" }));
        yield put(resetMe());
    } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
            removeToken();
            yield put(
                push({
                    pathname: "/",
                })
            );
            yield put(resetMe());
            handleAPIError(err.response);
        }
    } finally {
        yield put(setPendingLogout(false));
    }
}

type RegisterAccountPayload = {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    passwordConfirm: string;
};

export function* registerAccount(
    action: PayloadAction<RegisterAccountPayload>
): any {
    const error = yield select(selectError);
    const cancelTokenSource = axios.CancelToken.source();
    try {
        yield put(setRegisterStatus(RequestStatusEnum.PENDING));
        const { data } = yield call(() =>
            api.post(`/register`, action.payload, {
                cancelToken: cancelTokenSource.token,
            })
        );
        yield put(setRegisterStatus(RequestStatusEnum.SUCCESS));
        setToken(data.jwt);
        yield put(push("/app/dashboard"));
        yield put(setError(null));
    } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
            handleAPIError(err.response);
            yield put(setRegisterStatus(RequestStatusEnum.FAILED));
            if (err.response.data?.errors) {
                yield put(setError({ ...error, ...err.response.data?.errors }));
            }
        }
    }
}
