import { takeLatest } from 'redux-saga/effects';
import {
  SAGA_ACTIONS,
  fetchAllActiveCourses,
  fetchAllCourses,
  fetchCourseDetails,
} from './sagas';

const {
  FETCH_ALL_ACTIVE_COURSES,
  FETCH_ALL_COURSES,
  FETCH_COURSE_DETAILS
} = SAGA_ACTIONS;

const actions = [
  takeLatest(FETCH_ALL_ACTIVE_COURSES, fetchAllActiveCourses),
  takeLatest(FETCH_ALL_COURSES, fetchAllCourses),
  takeLatest(FETCH_COURSE_DETAILS, fetchCourseDetails),
];

export default actions;
