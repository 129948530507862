import React from "react";

import { Helmet } from "react-helmet";

import PublicPageLayout from "components/layouts/PublicPageLayout";

const PrivacyPolicyPage = () => {
    return (
        <PublicPageLayout>
            <Helmet>
                <title>iKnow Academic Hub: Privacy Policy</title>
                <meta
                    name="Privacy Policy"
                    content="Welcome to iKnow Academic Hub!"
                />
            </Helmet>
            <section className="section-why bg-iknow-lblue-1 !px-[50px] lg:!px-[100px] !py-[80px]">
                <p className="text-center font-bold text-[20px] lg:text-[32px] font-roboto pb-[30px]">
                    PRIVACY POLICY
                </p>
                <div className="w-full max-w-[800px] !mx-auto text-iknow-blue text-[14px] lg:text-[16px]">
                    <p className="font-bold text-center !pb-[50px]">
                        PLEASE READ THIS DOCUMENT CAREFULLY.
                    </p>
                    <p className="!pb-[15px]">
                        This Privacy Policy applies to the iKnow Academic Hub
                        websites and related subdomains, mobile applications,
                        and media (our &quot;website&qout;) managed by iKnow
                        Online Tutorial Service by Raymond Hernandez
                        (&quot;iKnow Academic Hub&quot;, &quot;we&quot;, or
                        &quot;us&quot;).
                        <br />
                        <br />
                        We respect your privacy and are committed to maintaining
                        and using any information we collect through your use of
                        the website responsibly. This Privacy Policy addresses
                        our use and disclosure of the information we collect
                        from you.
                        <br />
                        <br />
                        Please read this Privacy Policy carefully prior to
                        accessing or using our website or our services. If you
                        have any questions, please contact as at:
                        support@iknowacademichub.com
                    </p>
                    <p className="font-bold !pb-[10px]">
                        The Information We May Collect
                    </p>
                    <p className="!pb-[15px]">
                        Our primary purpose in collecting information from or
                        about you is to provide you with a safe, smooth,
                        efficient, and customised experience. Depending on the
                        manner in which you our website or our services, we
                        collect information about you that we deem necessary for
                        providing services and features that optimise, secure,
                        and ease your user experience.
                        <br />
                        <br />
                        Personal Information (&quot;PII&quot;): iKnow Academic
                        Hub may collect and process information that could be
                        directly associated with you, without the aid of
                        additional information; information such as your full
                        name, company/school name where applicable, username and
                        password, phone number, email address, billing or
                        mailing address, or credit/debit card information. You
                        can choose not to provide us with certain information,
                        but that may result in you being unable to use certain
                        features of our website because such information may be
                        required in order for you to register for an account;
                        purchase products or services; participate in a contest,
                        promotion, survey, or sweepstakes; ask a question; or
                        initiate other transactions on our website.
                        <br />
                        <br />
                        Payment Information:* *For the purposes of billing,
                        iKnow Academic Hub Plan subscription fees and iKnow
                        Academic Hub powered course enrolment fees that are
                        processed through iKnow Academic Hub&apos;s payment
                        gateways, we may request your credit card and certain
                        contact information, such as a billing address. We
                        retain credit card information internally for the
                        purpose of all payment processing related to our
                        services.
                        <br />
                        <br />
                        Third Party Payment Processors:* *Payments processed via
                        Custom Payment Gateways by third parties like PayPal and
                        Stripe are subject to the terms of use and privacy
                        policies of those payment gateways and are not
                        accessible or controlled by iKnow Academic Hub. For
                        payments processed via iKnow Academic Hub Payment and
                        Custom Payment Gateways, we receive information related
                        to the payment status of your account; this information
                        includes, but is not limited to, your payment card type
                        and last four digits, postal code, country of origin,
                        and payment expiration date. For additional information,
                        we recommend that you review the payment
                        processor&apos;s Privacy Policy.
                        <br />
                        <br />
                        Information from Third Party Platforms: You may allow
                        our website to interact with one or more third party
                        social networks or platforms (such as Facebook), which
                        will provide data about you to us
                        (&quot;Platform(s)&quot;). The information you allow
                        iKnow Academic Hub to access varies by Platform, and it
                        is affected by the privacy settings you and those that
                        you are connected with establish while using such
                        Platforms. Platforms are operated, controlled, and
                        maintained by third parties that are not operated,
                        controlled, or maintained by iKnow Academic Hub. We
                        recommend that you read the terms of use or service and
                        privacy policies of those Platforms to understand how
                        they collect and treat your data and what data they
                        might share with us or other third parties.
                        <br />
                        <br />
                        Demographic and Usage Data (Non-Personal Information):
                        iKnow Academic Hub may collect information through the
                        use of cookies, HTML5 local storage, and other similar
                        technologies. &quot;Cookies&quot; are alphanumeric
                        identifiers in the form of text files that are inserted
                        and stored by your web browser on your hard drive. iKnow
                        Academic Hub may set and access cookies on your computer
                        or wireless device to track and store preferential
                        information about you. We may gather anonymous
                        information about Users through cookie technology on an
                        individual and aggregate level. Such information is used
                        within iKnow Academic Hub internally and is only shared
                        with third party advertisers, if any, on an aggregated
                        or non-personally identifiable basis. You may opt out of
                        receiving cookies from our website by following the
                        instructions in your web browser. Note, however, that
                        deleting cookies or directing your browser to refuse
                        them may limit your ability to use certain portions of
                        our website that require cookies to function. iKnow
                        Academic Hub gathers certain information automatically
                        and stores it in log files. This information may include
                        Internet protocol (IP) addresses, browser type,
                        operating system, and other usage information about the
                        use of our website, including a history of the pages you
                        view.
                        <br />
                        <br />
                        *Non-Personal Information. *Web Beacons, also known as
                        pixel tags and clear GIFs, (&quot;Web Beacons&quot;),
                        are electronic images that allow a website to access
                        cookies and help track marketing campaigns and general
                        usage patterns of visitors to those websites. Web
                        Beacons can recognize certain types of information, such
                        as cookie numbers, time and date of a page view, and a
                        description of the page where the Web Beacons are
                        placed. No PI about you is shared with third parties
                        through the use of Web Beacons on our website. However,
                        through Web Beacons, we may collect general information
                        that will not personally identify you, such as: Internet
                        browser, operating system, IP address, date of visit,
                        time of visit and path taken through the website. iKnow
                        Academic Hub may also collect other Non-Personal
                        Information as visitors browse our website, such as the
                        web traffic, what pages are visited, from where visitors
                        come, and other demographic information, such as age,
                        gender, and search habits.
                    </p>
                    <p className="font-bold !pb-[10px]">
                        How We Use the Information We Collect
                    </p>
                    <p className="!pb-[15px]">
                        We may use the information we collect for a number of
                        purposes. We may use it to respond to your inquiries and
                        otherwise correspond with you, to process transactions
                        that you request, to maintain accounts, such as your
                        iKnow Academic Hub account, to preserve the security and
                        integrity of our website and services, to conduct market
                        research or surveys, and to administer, review, and/or
                        improve the content of our website and services. We may
                        also use the information to communicate with you, and
                        make recommendations, about Courses and other services
                        that we think may be of interest, or to address refund,
                        chargebacks, payment or copyright disputes.
                        <br />
                        <br />
                        iKnow Academic Hub may use Web Beacons internally to
                        count visitors and recognize visitors through cookies.
                        We may compile this Non-Personal Information to enable
                        statistical analysis of our services that would be used
                        internally or with external assistance to make
                        improvements to our website and services.
                        <br />
                        <br />
                        iKnow Academic Hub may use and share Non-Personal
                        Information we collect with third parties to develop and
                        deliver targeted advertising on our website or
                        applications of third parties, and to analyze and report
                        on advertising you see. We may combine Non-Personal
                        Information we collect with additional Non-Personal
                        Information collected from other sources. We also may
                        share aggregated, non-personal information, or personal
                        information in hashed, non-human readable form, with
                        third parties, including advisors, advertisers and
                        investors. For example, we may engage a third party
                        acting on our behalf to collect web log data from you
                        (including IP address and information about your browser
                        or operating system) when you use our website and other
                        websites you may access during your Internet browsing
                        session, or place or recognize a unique cookie on your
                        browser to enable you to receive customized
                        advertisements or content. For more information
                        regarding your choices about how third parties collect
                        information about you on our website see the section
                        titled Do-Not-Track Procedures below.
                        <br />
                        <br />
                        Except as stated above, iKnow Academic Hub may disclose
                        any of your information, which in some cases includes
                        PII, in the following situations:
                    </p>
                    <p className="!pl-[60px]">
                        1.With your consent. iKnow Academic Hub may transmit
                        your PII to a third party when you give us express
                        permission to do so. For example, this may occur when we
                        complete a transaction on your behalf or at your
                        request.
                        <br />
                        <br />
                        2.With our authorized service providers. iKnow Academic
                        Hub may share your PII with our authorized service
                        providers that perform certain services on our behalf.
                        These services may include processing credit card
                        payments, providing customer service and marketing
                        assistance, performing business and sales analysis,
                        supporting our website functionality, and supporting
                        contests, sweepstakes, surveys and other features
                        offered through our website. These service providers may
                        have access to PII needed to perform their functions but
                        are not permitted to share or use such information for
                        any other purposes.
                        <br />
                        <br />
                        3.With our business partners. When you make purchases,
                        reservations or engage in promotions offered through our
                        website, we may share PII with the businesses with which
                        we partner to offer you those products, services,
                        promotions, contests and/or sweepstakes. When you elect
                        to engage in a particular merchant&apos;s offer or
                        program, you authorize us to provide your email address
                        and other information to that merchant.
                        <br />
                        <br />
                        4.In connection with a substantial corporate
                        transaction, such as the sale of our business; a
                        divestiture, merger, consolidation, or asset sale; or in
                        the event of bankruptcy. If another company acquires
                        iKnow Academic Hub, that company will take on all
                        responsibility for the information we collect, including
                        PII, and it will assume all rights and obligations with
                        respect to that information. Should this happen, the
                        acquiring company may implement its own policies with
                        respect to your information.
                        <br />
                        <br />
                        5.iKnow Academic Hub may use information available on
                        the performance and availability of iKnow Academic Hub
                        powered websites for advertising purposes on
                        iknow.com.ph, and external outlets. Additionally, iKnow
                        Academic Hub may use the information available within a
                        Student&apos;s account for promotional activities unless
                        and until the Student informs us that they no longer
                        wish to receive any promotional materials.
                        <br />
                        <br />
                        6.If we are required to disclose information by law.
                        iKnow Academic Hub may be obligated to disclose a
                        User&apos;s personal information, if directed by a court
                        of law or other governmental entity. Without limiting
                        the foregoing, we reserve the right to disclose such
                        information where we have a good faith basis to believe
                        that such action is necessary to: (a) comply with the
                        law; (b) protect and defend iKnow Academic Hub&apos;s
                        rights and property, or safety of iKnow Academic Hub,
                        our users, our employees, or others; (c) protect against
                        misuse or unauthorized use of our website and/or
                        Services and/or to enforce our Terms of Use or other
                        agreements or policies; and/or (d) protect your personal
                        safety or property or that of the public. In the event
                        that your information is disclosed, we will comply with
                        the law and make commercially reasonable efforts to
                        notify you.
                    </p>
                    <br />
                    <p className="!pb-[15px]">
                        Please keep in mind that all of the information that you
                        disclose or content that you post in your user profile,
                        forum posts, comments, or other public portions of our
                        website becomes public information. Because this
                        information is publicly available, we cannot control how
                        others treat it so we ask all Users to exercise care in
                        deciding what information they chose to share. If you
                        terminate your iKnow Academic Hub account, we may retain
                        your PII for administrative purposes but will not
                        publicly or share it in any other way. This does not
                        mean that we will scrub your posts from our publicly
                        available information.
                    </p>
                    <p className="font-bold !pb-[10px]">
                        New Uses of Information
                    </p>
                    <p className="!pb-[15px]">
                        From time to time, we may use visitor information for
                        new, unanticipated uses not previously disclosed in our
                        Privacy Policy. If our information practices change at
                        some time in the future, we will post the policy changes
                        to our website to notify you of these changes. If you
                        are concerned about how your information is used, you
                        should visit our website periodically and review our
                        policies.
                    </p>
                    <p className="font-bold !pb-[10px]">Data Retention</p>
                    <p className="!pb-[15px]">
                        We will retain your data for a period of up to three (3)
                        months (or the maximum time required by law) after your
                        account (a) has been cancelled, (b) is on a credit hold
                        for nonpayment, or (c) ends after a trial period. After
                        this time, the information will be permanently removed
                        from our servers and no one will be able to access it.
                        Your personal information such as your email, name, type
                        of account you had, and payment history will remain on
                        file for a period of up to ten (10) years, or the
                        maximum time required by law, to comply with government
                        regulations. Again, this does not mean that we will
                        scrub your posts from our publicly available
                        information.
                    </p>
                    <p className="font-bold !pb-[10px]">
                        Access to Your Information
                    </p>
                    <p className="!pb-[15px]">
                        iKnow Academic Hub will provide you with access to PII
                        that you submitted to iKnow Academic Hub for as long as
                        we maintain that information in a readily accessible
                        format. If you believe that any PII we are holding is
                        incorrect or incomplete, or to have iKnow Academic Hub
                        completely remove your PII from our systems, please send
                        an email with your specific request to
                        support@iknowacademichub.com. We will work with you to
                        make any corrections deemed necessary. We may not
                        accommodate a request to change information if we
                        believe the change would violate any law or legal
                        requirement or cause the information to be incorrect.
                        Additionally, note that registered Instructors and
                        Students may change visibility of their respective
                        personal information throughout our website by editing
                        their personal profiles.
                        <br />
                        <br />
                        If you provide your email address to iKnow Academic Hub,
                        you will always have the opportunity to opt out of
                        receiving email newsletters and promotions through the
                        unsubscribe link in the email communications or by
                        logging into and changing the preferences for your user
                        account. We may send you other types of transactional
                        and relationship e-mail communications, such as service
                        announcements, administrative notices, and surveys,
                        without offering you the opportunity to opt out of
                        receiving them. Please note that changing information in
                        your account, or otherwise opting out of receipt of
                        promotional email communications will only affect future
                        activities or communications from us. If we have already
                        provided your information to a third party (such as a
                        credit card processing partner) before you changed your
                        preferences or updated your information, you may have to
                        change your preferences directly with that third party.
                    </p>
                    <p className="font-bold !pb-[10px]">
                        Our Policy Concerning Children
                    </p>
                    <p className="!pb-[15px]">
                        As stated in our Terms of Use, our website and services
                        are offered and available only to you if are 18 years or
                        older, or if you are between 13 and 18 years old and
                        have obtained the consent of a parent or legal guardian
                        prior to accessing the website, registering an account,
                        or using the services. If you do not meet this
                        eligibility requirement, you may not use our website or
                        services. iKnow Academic Hub does not knowingly collect
                        or store any personal information from or about children
                        under the age of 13. We will delete such information if
                        we become aware that it has been submitted through our
                        website. Parents or legal guardians who believe that
                        iKnow Academic Hub might have any information from or
                        about a child under the age of 13 may submit a request
                        to support@iknowacademichub.com and request that such
                        information be removed.
                    </p>
                    <p className="font-bold !pb-[10px]">
                        Securing Your Information
                    </p>
                    <p className="!pb-[15px]">
                        iKnow Academic Hub takes a range of security measures
                        designed to protect your PII and keep it confidential
                        (unless it is non-confidential by nature) and free from
                        any unauthorized alteration. For example, only
                        authorized employees are permitted to access personal
                        information, and they may do so only for permitted
                        business functions. In addition, we use encryption in
                        the transmission of financial information between your
                        system and ours, and we use firewalls to help prevent
                        unauthorised persons from gaining access to your
                        personal information.
                        <br />
                        <br />
                        Where we have given you (or where you have chosen) a
                        password for access to certain parts of our website, you
                        are responsible for keeping this password confidential.
                        As the safety and security of your information also
                        depends on the precautions you take, we ask you not to
                        share your password with anyone.
                    </p>
                    <p className="font-bold !pb-[10px]">
                        Third Parties and Other Information Collectors
                    </p>
                    <p className="!pb-[15px]">
                        Except as otherwise expressly included in this Privacy
                        Policy, this document only addresses the use and
                        disclosure of information we collect from you. To the
                        extent that you disclose your information to other
                        parties through our website, whether between Instructor
                        and Student or other Users or individuals, different
                        rules may apply to their use, collection, and disclosure
                        of the personal information you disclose to them. Since
                        we do not control the information use, collection, or
                        disclosure policies of third parties, you are subject to
                        their privacy policies.
                        <br />
                        <br />
                        Our website may include links to third party websites.
                        Once you have used these links to leave our website, you
                        should note that we do not have any control over third
                        party websites. We are not responsible for the content
                        of such websites or the protection and privacy of any
                        information which you provide while visiting such sites.
                        Third-party websites are not governed by this Privacy
                        Policy. You should exercise caution and look at the
                        privacy policies applicable to the websites in question.
                    </p>
                    <p className="font-bold !pb-[10px]">
                        Do-Not-Track Procedures
                    </p>
                    <p className="!pb-[15px]">
                        Note that any Do-Not-Track instructions from your
                        browser or similar mechanism will not affect our
                        collection of the data that you voluntarily provide to
                        iKnow Academic Hub through such means as browsing our
                        website, signing up for any of the Services or any
                        Courses, entering PII into information fields on our
                        website, or posting on any of our forums or blogs. Be
                        advised that third parties are free to collect any
                        information that you volunteer to Video School Online
                        that is visible to the public. Therefore, please do not
                        provide Video School Online with any information that
                        you do not want collected.
                    </p>
                    <p className="font-bold !pb-[10px]">
                        Users Outside the Philippines
                    </p>
                    <p className="!pb-[15px]">
                        The Sites are hosted in the Philippines and are governed
                        by the laws of the Philippines. If you are using the
                        site outside the Philippines, please be aware that your
                        information will be transferred to, stored, and
                        processed in the Philippines where iKnow Academic
                        Hub&apos;s servers and databases are located. By using
                        the Sites, you consent to the transfer of information to
                        countries outside your country of residence.
                    </p>
                    <p className="font-bold !pb-[10px]">
                        No Rights of Third Parties
                    </p>
                    <p className="!pb-[15px]">
                        This Privacy Policy does not create rights enforceable
                        by third parties or require disclosure of any personal
                        information relating to users of the website.
                    </p>
                    <p className="font-bold !pb-[10px]">
                        Changes to the Privacy Policy
                    </p>
                    <p className="!pb-[15px]">
                        We may review and update this Privacy Policy from time
                        to time in our sole discretion. It is the responsibility
                        of Users to keep abreast of those changes. All changes
                        are effective immediately when posted. As such, your
                        continued use of the Sites or Services after iKnow
                        Academic Hub makes changes to this Privacy Policy is
                        deemed to be acceptance of those changes, so please
                        check this webpage periodically for updates. In
                        addition, iKnow Academic Hub ill notify Users of any
                        material or significant changes to this Privacy Policy
                        relating to the way in which we use your personal
                        information by placing a notice on the Sites and
                        notifying registered Users via email.
                    </p>
                </div>
            </section>
        </PublicPageLayout>
    );
};

export default PrivacyPolicyPage;
