import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

export const handleAPIError = (errorResponse: AxiosResponse<any>) => {
  const data = errorResponse?.data;
  
  if (data?.error) {
    toast.error(data?.error?.message);
  }

  if (data?.message) {
    toast.error(data?.message);
  }
};