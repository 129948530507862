import React, { useState } from "react";
import AppLayout from "components/layouts/AppLayout";
import onlineTest from "assets/images/freepik/online_test.png";
import entranceExam from "assets/images/freepik/entrance_exam.png";
import calendar from "assets/images/freepik/calendar.png";
import nitrogenCycle from "assets/images/freepik/nitrogen_cycle.png";
import notes from "assets/images/freepik/notes.png";
import notebook from "assets/images/freepik/notebook.png";
import list from "assets/images/freepik/list.png";
import stress from "assets/images/freepik/stress.png";
import graduation from "assets/images/freepik/graduation.png";
import { tips } from "common/constants";
import { useEffectOnce } from "react-use";
import useAppSelector from "hooks/useAppSelector";
import { selectSubscriptions } from "modules/me/slice";
import * as _ from "lodash";
import { ISubscription } from "common/types";
import { Link } from "react-router-dom";

const Dashboard = () => {
    const subscriptions = useAppSelector(selectSubscriptions);
    const sortedSubscriptions = _.orderBy(
        subscriptions,
        [
            function (o) {
                return o.price;
            },
        ],
        ["desc"]
    );
    const [tip, setTip] = useState<number>(0);

    useEffectOnce(() => {
        setTip(Math.floor(Math.random() * tips.length));
    });

    return (
        <AppLayout>
            {sortedSubscriptions && sortedSubscriptions?.length > 0 ? (
                <div className="relative m-[30px] bg-slate-500 py-[25px] sm:py-[45px] rounded-[25px] min-h-[400px] grid grid-cols-1 lg:grid-cols-4 overflow-hidden">
                    <img
                        src={notes}
                        alt="sample pik"
                        className="absolute right-[-10vw] bottom-[-50px] lg:left-[-10vw] lg:top-[50%] lg:translate-y-[-50%] h-auto w-0 sm:w-[40vw] md:w-[30vw]"
                    />
                    <div className="px-[25px] sm:px-[45px] col-span-2 lg:col-start-2 lg:col-span-3 font-roboto text-white">
                        <p className="font-bold text-[16px] sm:text-[20px] md:text-[28px] pb-[20px]">
                            {sortedSubscriptions[0].courseName}
                        </p>
                        <p className="text-[12.5px] md:text-[16px]">
                            {sortedSubscriptions[0].courseDescription}
                        </p>
                        {/* <div className='pt-[40px] flex text-[12px] font-bold gap-[10px]'>
              <p className='bg-iknow-blue rounded-full py-[2.5px] px-[15px] w-fit'>13 Lessons</p>
              <p className='bg-iknow-blue rounded-full py-[2.5px] px-[15px] w-fit'>76 Topics</p>
            </div> */}
                        <div className="pt-[40px]">
                            <Link
                                to={`/app/courses/${sortedSubscriptions[0].id}`}
                            >
                                <button className="rounded-full border-2 border-white py-[2.5px] px-[15px] text-[12px] md:text-[18px]">
                                    VIEW TOPICS
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="relative m-[30px] bg-slate-500 py-[25px] sm:py-[45px] rounded-[25px] min-h-[400px] grid grid-cols-1 lg:grid-cols-4 overflow-hidden">
                    <img
                        src={stress}
                        alt="sample pik"
                        className="absolute right-[-10vw] bottom-[-50px] lg:left-[-10vw] lg:top-[50%] lg:translate-y-[-50%] h-auto w-0 sm:w-[40vw] md:w-[30vw]"
                    />
                    <div className="px-[25px] sm:px-[45px] col-span-2 lg:col-start-2 lg:col-span-3 font-roboto text-white">
                        <p className="font-bold text-[16px] sm:text-[20px] md:text-[28px] pb-[20px]">
                            OOPS... You are not subscribed to any courses.
                        </p>
                        <p className="text-[12.5px] md:text-[16px]">
                            Add courses to start learning.
                        </p>
                        <div className="pt-[40px]">
                            <Link to="/app/course-offerings">
                                <button className="rounded-full border-2 border-white py-[2.5px] px-[15px] text-[12px] md:text-[18px]">
                                    VIEW COURSES
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            )}
            <div className="grid grid-cols-1 lg:grid-cols-3">
                <div className="relative col-span-1 m-[30px] bg-yellow-600 p-[25px] sm:p-[45px] rounded-[25px] min-h-[400px] grid grid-cols-1 xl:grid-cols-3 overflow-hidden">
                    <img
                        src={onlineTest}
                        alt="entrance_exam"
                        className="absolute h-auto w-[70vw] sm:w-[45vw] md:w-[30vw] xl:w-[15vw] right-[-2.5vw] bottom-[-120px] sm:bottom-[-50px]"
                    />
                    <div className="xl:col-span-2 font-roboto text-white">
                        <p className="font-bold text-[20px] xl:text-[28px] pb-[20px]">
                            Announcements
                        </p>
                        <p className="text-[12.5px] md:text-[16px]">
                            Diagnostic Exam is available now. Learn your current
                            capabilities.
                        </p>
                        <div className="pt-[40px]">
                            <button className="rounded-full border-2 border-white py-[2.5px] px-[15px] text-[12px] md:text-[18px]">
                                Take Exam
                            </button>
                        </div>
                    </div>
                </div>
                <div className="relative col-span-2 grid sm:grid-cols-4 m-[30px] bg-slate-800 p-[25px] sm:p-[45px] rounded-[25px] min-h-[400px] overflow-hidden">
                    <div className="absolute w-0 sm:w-[40vw] md:w-[25vw] right-[-10vw] bottom-[-50px] md:top-[50%] md:translate-y-[-50%]">
                        {tips[tip].image === "entrance_exam" ? (
                            <img src={entranceExam} alt="entrance_exam" />
                        ) : tips[tip].image === "calendar" ? (
                            <img src={calendar} alt="calendar" />
                        ) : tips[tip].image === "nitrogen_cycle" ? (
                            <img src={nitrogenCycle} alt="nitrogen_cycle" />
                        ) : tips[tip].image === "notes" ? (
                            <img src={notes} alt="notes" />
                        ) : tips[tip].image === "list" ? (
                            <img src={list} alt="list" />
                        ) : tips[tip].image === "stress" ? (
                            <img src={stress} alt="stress" />
                        ) : tips[tip].image === "graduation" ? (
                            <img src={graduation} alt="graduation" />
                        ) : (
                            <img src={notebook} alt="notebook" />
                        )}
                    </div>
                    <div className="font-roboto text-white col-span-3">
                        <p className="font-bold text-[20px] md:text-[24px] pb-[20px]">
                            {tips[tip].title}
                        </p>
                        <p className="text-[12.5px] md:text-[16px]">
                            {tips[tip].content}
                        </p>
                        <div className="pt-[20px] text-[12px]">
                            <p className="bg-yellow-600 rounded-full my-[5px] py-[2.5px] px-[15px] w-fit">
                                #randomTipsForEntranceExamPreparation
                            </p>
                            <div className="flex">
                                <p className="bg-yellow-600 rounded-full my-[5px] mr-[10px] py-[2.5px] px-[15px] w-fit">
                                    #acingCETs
                                </p>
                                <p className="bg-yellow-600 rounded-full my-[5px] py-[2.5px] px-[15px] w-fit">
                                    #iKnowTips
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {sortedSubscriptions && sortedSubscriptions?.length > 1 ? (
                <div>
                    {_.map(
                        _.slice(
                            sortedSubscriptions,
                            1,
                            sortedSubscriptions.length
                        ),
                        (course: ISubscription) => {
                            return (
                                <div
                                    key={course.id}
                                    className="relative m-[30px] bg-slate-500 py-[25px] sm:py-[45px] rounded-[25px] min-h-[400px] grid grid-cols-1 lg:grid-cols-4 overflow-hidden"
                                >
                                    <img
                                        src={notes}
                                        alt="sample pik"
                                        className="absolute right-[-10vw] bottom-[-50px] lg:left-[-10vw] lg:top-[50%] lg:translate-y-[-50%] h-auto w-0 sm:w-[40vw] md:w-[30vw]"
                                    />
                                    <div className="px-[25px] sm:px-[45px] col-span-2 lg:col-start-2 lg:col-span-3 font-roboto text-white">
                                        <p className="font-bold text-[16px] sm:text-[20px] md:text-[28px] pb-[20px]">
                                            {course.courseName}
                                        </p>
                                        <p className="text-[12.5px] md:text-[16px]">
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: course.courseDescription,
                                                }}
                                            />
                                        </p>
                                        {/* <div className='pt-[40px] flex text-[12px] font-bold gap-[10px]'>
                    <p className='bg-iknow-blue rounded-full py-[2.5px] px-[15px] w-fit'>13 Lessons</p>
                    <p className='bg-iknow-blue rounded-full py-[2.5px] px-[15px] w-fit'>76 Topics</p>
                  </div> */}
                                        <div className="pt-[40px]">
                                            <Link
                                                to={`/app/courses/${course.id}`}
                                            >
                                                <button className="rounded-full border-2 border-white py-[2.5px] px-[15px] text-[12px] md:text-[18px]">
                                                    VIEW TOPICS
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    )}
                </div>
            ) : (
                <div>ADD COURSES</div>
            )}
        </AppLayout>
    );
};

export default Dashboard;
