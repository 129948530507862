import isNil from 'lodash/isNil';
import trim from 'lodash/trim';

export function setToken(token: string): void {
  window.localStorage.setItem('auth-token', token);
}

export function removeToken(): void {
  window.localStorage.removeItem('auth-token');
}

export function getToken(): string | null {
  return window.localStorage.getItem('auth-token');
}

export const isEmpty = (value: string | null | undefined): boolean => {
  return isNil(value) || trim(value) === '';
};

export const getErrorValue = (error:any) => {
  if(!isEmpty(error))
  return error[Object.keys(error)[0]] ? error[Object.keys(error)[0]][0] : null;
};