import ACTIONS from "modules/rootActions";
import useAppDispatch from "./useAppDispatch";

const useLogout = () => {
  const dispatch = useAppDispatch();

  const logout = () => dispatch({type: ACTIONS.LOGOUT_ACCOUNT,});

  return {
    logout
  };
}

export default useLogout;