import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const AppLayout = ({ children }: Props) => {
  return<main className='md:px-[20px] lg::px-[50px] xl:px-[80px] py-[30px] md:py-[60px] bg-slate-300 min-h-[calc(100vh-120px)]'>{children}</main>;
};

export default AppLayout;
