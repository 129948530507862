import React from "react";
import { Route, Switch } from "react-router-dom";
import PageLayout from "components/layouts/PageLayout";
import { AuthNavigation } from "components/Navigation/AuthNavigation";
import AppLayout from "components/layouts/AppLayout";
import Dashboard from "features/Dashboard";
import Topics from "features/Topics";
import Lessons from "features/Lessons";
import MyAccount from "features/MyAccount";

const AppPage = () => {
  return (
    <PageLayout>
      <AuthNavigation />
      <AppLayout>
        <Switch>
          <Route exact path="/app/dashboard" component={Dashboard} />
          <Route exact path="/app/account" component={MyAccount} />
          <Route exact path="/app/courses/:courseId" component={Topics} />
          <Route path="/app/topics/:topicId" component={Lessons} />
        </Switch>
      </AppLayout>
    </PageLayout>
  )
}

export default AppPage;