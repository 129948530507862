import React from 'react';
import PublicPageLayout from 'components/layouts/PublicPageLayout';
import FaqsList from './FaqsList';
import { Helmet } from 'react-helmet';
import Hero from 'components/Hero';
import Studying from "assets/images/hero/studying.png";

const FrequentlyAskedQuestions = () => {

  return (
    <PublicPageLayout>
      <Helmet>
        <title>iKnow Academic Hub: Frequently Asked Questions</title>
        <meta name="Frequently Asked Questions" content="Welcome to iKnow Academic Hub!" />
      </Helmet>
      <Hero title='Frequently Asked Questions' image={Studying} />
      <FaqsList />
    </PublicPageLayout>
  )
}

export default FrequentlyAskedQuestions;