import React, { useEffect, useState } from "react";

import { PropagateLoader } from "react-spinners";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as Yup from "yup";

import { browserHistory } from "app/history";
import { RequestStatusEnum } from "common/types";
import { InvalidText, Title } from "components/Typography";
import {
    selectError,
    selectRegisterStatus,
    setIsLoginModalOpen,
} from "modules/auth/slice";
import ACTIONS from "modules/rootActions";
import useAppDispatch from "hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";
import CustomInput from "components/CustomInput";
import PublicPageLayout from "components/layouts/PublicPageLayout";

type FormValues = {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    passwordConfirm: string;
};

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .required("Email is required")
        .email("Invalid email format"),
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    password: Yup.string().required("Password is required"),
    passwordConfirm: Yup.string().required("Password Confirm is required"),
});

const Registration = () => {
    const dispatch = useAppDispatch();
    const registrationsStatus = useAppSelector(selectRegisterStatus);
    const error = useAppSelector(selectError);
    const [agree, setAgree] = useState("no");

    const formOptions = {
        resolver: yupResolver(validationSchema),
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormValues>(formOptions);

    const onSubmit: SubmitHandler<FormValues> = ({
        email,
        firstName,
        lastName,
        password,
        passwordConfirm,
    }) => {
        dispatch({
            type: ACTIONS.REGISTER_ACCOUNT,
            payload: {
                email,
                firstName,
                lastName,
                password,
                passwordConfirm,
            },
        });
    };

    useEffect(() => {
        if (registrationsStatus === RequestStatusEnum.SUCCESS) {
            Swal.fire({
                title: '<p class="font-bold font-q">Registration successful!</p>',
                icon: "success",
                iconColor: "#1f3462",
                html: "Welcome to<br/>iKnow Academic Hub!",
                showCloseButton: false,
                showCancelButton: false,
                focusConfirm: false,
                allowEscapeKey: true,
                allowOutsideClick: true,
                confirmButtonColor: "#1f3462",
                confirmButtonText: "Go to My Dashboard",
            }).then((result) => {
                if (result.isConfirmed) {
                    browserHistory.push("/app/dashboard");
                }
            });
        }
    }, [registrationsStatus]);

    return (
        <PublicPageLayout>
            <section className="px-8 md:px-12 w-full md:w-[800px] py-6 md:py-16">
                <Title>Registration</Title>
                <div className="p-8 md:p-12 rounded-[20px] bg-iknow-yellow">
                    <form
                        action="post"
                        className="grid grid-cols-1 gap-[10px]"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <CustomInput
                            type="text"
                            name="email"
                            placeholder="Email address"
                            required={true}
                            error={errors?.email}
                            register={register}
                        />
                        <InvalidText>{errors.email?.message}</InvalidText>

                        <CustomInput
                            type="text"
                            name="firstName"
                            placeholder="First name"
                            required={true}
                            error={errors?.firstName}
                            register={register}
                        />
                        <InvalidText>{errors.firstName?.message}</InvalidText>

                        <CustomInput
                            type="text"
                            name="lastName"
                            placeholder="Last name"
                            required={true}
                            error={errors?.lastName}
                            register={register}
                        />
                        <InvalidText>{errors.lastName?.message}</InvalidText>

                        <CustomInput
                            type="password"
                            name="password"
                            placeholder="Password"
                            required={true}
                            error={errors?.password}
                            register={register}
                        />
                        {typeof error === "string" ? (
                            !error.includes("inactive") && (
                                <InvalidText>{error}</InvalidText>
                            )
                        ) : (
                            <InvalidText>
                                {errors.password?.message}
                            </InvalidText>
                        )}

                        <CustomInput
                            type="password"
                            name="passwordConfirm"
                            placeholder="Re-type Password"
                            required={true}
                            error={errors?.password}
                            register={register}
                        />
                        {typeof error === "string" ? (
                            !error.includes("inactive") && (
                                <InvalidText>{error}</InvalidText>
                            )
                        ) : (
                            <InvalidText>
                                {errors.password?.message}
                            </InvalidText>
                        )}

                        <label htmlFor="agree" className="flex items-start">
                            <input
                                type="checkbox"
                                name="agree"
                                className="mr-[5px] mt-[2px]"
                                onChange={() => {
                                    if (agree === "no") {
                                        setAgree("yes");
                                    } else {
                                        setAgree("no");
                                    }
                                }}
                                value={agree}
                            />
                            <span className="text-[12px] md:text-[14px]">
                                By registering an account and submitting my
                                information, I hereby declare that I have read
                                and understood the{" "}
                                <a
                                    href="/terms-and-conditions"
                                    className="uppercase font-bold"
                                >
                                    terms and conditions
                                </a>{" "}
                                and{" "}
                                <a
                                    href="/privacy-policy"
                                    className="uppercase font-bold"
                                >
                                    privacy policy
                                </a>{" "}
                                of iKnow Academic Hub.
                            </span>
                        </label>
                        <button
                            className="mt-8 md:mt-12 py-[10px] rounded-[10px] bg-iknow-blue disabled:bg-gray-500 text-white font-bold"
                            disabled={
                                (registrationsStatus !==
                                    RequestStatusEnum.PENDING &&
                                    agree === "no") ||
                                registrationsStatus ===
                                    RequestStatusEnum.PENDING
                            }
                        >
                            SUBMIT
                        </button>
                    </form>
                    <p className="mt-[30px] text-[14px] text-center">
                        Already have an account?{" "}
                        <span
                            onClick={() => dispatch(setIsLoginModalOpen(true))}
                            className="uppercase font-bold"
                        >
                            SIGN IN
                        </span>
                    </p>
                </div>
            </section>
            {registrationsStatus === RequestStatusEnum.PENDING && (
                <div className="z-[99999999] fixed top-0 left-0 w-[100vw] h-[100vh]">
                    <div className="relative w-full h-full">
                        <div className="absolute w-full h-full bg-black opacity-[50%]"></div>
                        <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-fit h-fit">
                            <PropagateLoader color="#ffffff" size={20} />
                        </div>
                    </div>
                </div>
            )}
        </PublicPageLayout>
    );
};

export default Registration;
