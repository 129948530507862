import React from "react";
import star from "assets/images/icons8/star-filled.png";
import cetCourse from "assets/images/courses/cet.png";
import letCourse from "assets/images/courses/let.png";
import csCourse from "assets/images/courses/cs.png";

const Courses = () => {
    return (
        <section className="section-courses bg-[#fdfbf9] text-iknow-blue min-h-[60vh] !px-[30px] lg:!px-[100px] !pt-[40px] lg:!pt-[20px] !pb-[80px]">
            <p className="text-center font-bold font-roboto text-[30px] lg:text-[48px]">
                Courses offered
            </p>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-[50px] lg:!mt-[40px]">
                <div className="text-center font-roboto bg-white">
                    <div className="!py-[40px] !px-[20px]">
                        <p className="font-bold text-[12px] text-[#c6c6c6]">
                            All Courses
                        </p>
                        <p className="text-[22px] !pt-[10px]">
                            Comprehensive CET Reviewer Online
                        </p>
                        <div className="w-max flex flex-row !mx-auto !pt-[40px]">
                            <img
                                src={star}
                                alt=""
                                className="h-[15px] !mr-[5px]"
                            />
                            <img
                                src={star}
                                alt=""
                                className="h-[15px] !mr-[5px]"
                            />
                            <img
                                src={star}
                                alt=""
                                className="h-[15px] !mr-[5px]"
                            />
                            <img
                                src={star}
                                alt=""
                                className="h-[15px] !mr-[5px]"
                            />
                            <img
                                src={star}
                                alt=""
                                className="h-[15px] !mr-[5px]"
                            />
                            <p className="text-[14px] text-[#c6c6c6]">(12)</p>
                        </div>
                    </div>
                    <img
                        src={cetCourse}
                        alt=""
                        className="!mx-auto !mb-[20px] w-full"
                    />
                </div>
                <div className="text-center font-roboto bg-white">
                    <div className="!py-[40px] !px-[20px]">
                        <p className="font-bold text-[12px] text-[#c6c6c6]">
                            All Courses
                        </p>
                        <p className="text-[22px] !pt-[10px]">
                            Comprehensive LET Reviewer Online
                        </p>
                        <div className="w-max flex flex-row !mx-auto !pt-[40px]">
                            <p className="text-[14px] text-[#c6c6c6]">
                                Coming soon
                            </p>
                        </div>
                    </div>
                    <img
                        src={letCourse}
                        alt=""
                        className="!mx-auto !mb-[20px] w-full"
                    />
                </div>
                <div className="text-center font-roboto bg-white">
                    <div className="!py-[40px] !px-[20px]">
                        <p className="font-bold text-[12px] text-[#c6c6c6]">
                            All Courses
                        </p>
                        <p className="text-[22px] !pt-[10px]">
                            Civil Service Exam Reviewer Online
                        </p>
                        <div className="w-max flex flex-row !mx-auto !pt-[40px]">
                            <p className="text-[14px] text-[#c6c6c6]">
                                Coming soon
                            </p>
                        </div>
                    </div>
                    <img
                        src={csCourse}
                        alt=""
                        className="!mx-auto !mb-[20px] w-full"
                    />
                </div>
            </div>
        </section>
    );
};

export default Courses;
