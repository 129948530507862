import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet';
import { ScrollToPlugin, ScrollTrigger } from "gsap/all";
import gsap from 'gsap';

import { PublicNavigation } from 'components/Navigation/PublicNavigation';
import PageLayout from 'components/layouts/PageLayout';
import Footer from 'components/Footer';
import Testimonials from './Testimonials';
import Courses from './Courses';
import Hero from './Hero';
import Why from './Why';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const HomePage = () => {
  
  const queryParameters = new URLSearchParams(location.search);
  const targetContent = queryParameters.get("ref");

  useEffect(() => {
		const timer = setTimeout(() => {
			gsap.registerPlugin(ScrollToPlugin);
			if (targetContent !== null) {
				const targetElem = document.getElementById(targetContent);
				if (targetElem !== null) {
					gsap.to(window, {
						scrollTo: { y: `#${targetContent}`, offsetY: 50 },
						duration: 0.6,
						ease: "Circ.easeOut",
					});
				}
			}
		}, 1000);
		return () => clearTimeout(timer);
	}, [targetContent]);

  return (
    <PageLayout>
      <Helmet>
        <title>iKnow Academic Hub: Welcome</title>
        <meta name="Home Page" content="Welcome to iKnow Academic Hub!" />
      </Helmet>
      <PublicNavigation />
      <Hero />
      <Courses />      
      <Why />
      <Testimonials />
      <Footer />
    </PageLayout>
  );
};

export default HomePage;
