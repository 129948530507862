import axios from 'axios';
// import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';
import { RequestStatusEnum } from 'common/types';
import { handleAPIError } from 'common/errorHandler';
import { authenticatedClient } from 'app/apiClient';
import {
  setAllLessons,
  setAllActiveLessons,
  setSelectedLesson,
  setAllActiveLessonsStatus,
  setAllLessonsStatus,
  setSelectedLessonStatus
} from './slice';
import { PayloadAction } from '@reduxjs/toolkit';

export const SAGA_ACTIONS = {
  FETCH_ALL_LESSONS: 'FETCH_ALL_LESSONS',
  FETCH_ALL_ACTIVE_LESSONS: 'FETCH_ALL_ACTIVE_LESSONS',
  FETCH_LESSON_DETAILS: 'FETCH_LESSON_DETAILS'
};

type FetchAllActiveLessonsPayload = {
  courseId: number;
}

export function* fetchAllActiveLessons(
  action: PayloadAction<FetchAllActiveLessonsPayload>,
): any {
  const api = authenticatedClient();

  try {
    yield put(setAllActiveLessonsStatus(RequestStatusEnum.PENDING));
    
    const { data } = yield call(() =>
    api.post('/lessons', action.payload)
    );
    yield put(setAllActiveLessons(data));
    yield put(setAllActiveLessonsStatus(RequestStatusEnum.SUCCESS))
  } catch (err){
    if(axios.isAxiosError(err) && err.response){
      handleAPIError(err.response);
      yield put(setAllActiveLessonsStatus(RequestStatusEnum.FAILED));
    }
  }
}

// type FetchAllLessonsPayload = {
//   // verified Admin
// }

export function* fetchAllLessons(
  // action: PayloadAction<FetchAllLessonsPayload>,
): any {
  const api = authenticatedClient();
  const payload = {

  }

  try {
    yield put(setAllLessonsStatus(RequestStatusEnum.PENDING));
    
    const { data } = yield call(() =>
      api.post('/lessons/all', payload)
    );
    yield put(setAllLessons(data));
    yield put(setAllLessonsStatus(RequestStatusEnum.SUCCESS))
  } catch (err){
    if(axios.isAxiosError(err) && err.response){
      handleAPIError(err.response);
      yield put(setAllLessonsStatus(RequestStatusEnum.FAILED));
    }
  }
}

type FetchLessonDetailsPayload = {
  lessonId: number;
}

export function* fetchLessonDetails(
  action: PayloadAction<FetchLessonDetailsPayload>,
): any {
  const api = authenticatedClient();

  try {
    yield put(setSelectedLessonStatus(RequestStatusEnum.PENDING));
    
    const { data } = yield call(() =>
      api.post('/lesson', action.payload)
    );
    yield put(setSelectedLesson(data));
    yield put(setSelectedLessonStatus(RequestStatusEnum.SUCCESS))
  } catch (err){
    if(axios.isAxiosError(err) && err.response){
      handleAPIError(err.response);
      yield put(setSelectedLessonStatus(RequestStatusEnum.FAILED));
    }
  }
}