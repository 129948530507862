import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import { ITopic, RequestStatusEnum } from 'common/types';


type InitialState = {
  allTopics: ITopic[] | null;
  allTopicsStatus: RequestStatusEnum | null;
  allActiveTopics: ITopic[] | null;
  allActiveTopicsStatus: RequestStatusEnum | null;
  selectedTopic: ITopic | null;
  selectedTopicStatus: RequestStatusEnum | null;
}

const initialState: InitialState = {
  allTopics: null,
  allTopicsStatus: null,
  allActiveTopics: null,
  allActiveTopicsStatus: null,
  selectedTopic: null,
  selectedTopicStatus: null,
}

export const slice = createSlice({
  name: 'topics',
  initialState,
  reducers: {
    reset: () => initialState,
    setAllTopics: (state, action: PayloadAction<ITopic[] | null>) => {
      state.allTopics = action.payload;
    },
    setAllTopicsStatus: (state, action: PayloadAction<RequestStatusEnum>) => {
      state.allTopicsStatus = action.payload;
    },
    setAllActiveTopics: (state, action: PayloadAction<ITopic[] | null>) => {
      state.allActiveTopics = action.payload;
    },
    setAllActiveTopicsStatus: (state, action: PayloadAction<RequestStatusEnum>) => {
      state.allActiveTopicsStatus = action.payload;
    },
    setSelectedTopic: (state, action: PayloadAction<ITopic | null>) => {
      state.selectedTopic = action.payload;
    },
    setSelectedTopicStatus: (state, action: PayloadAction<RequestStatusEnum>) => {
      state.selectedTopicStatus = action.payload;
    },
  }
});

export const {
  reset,
  setAllTopics,
  setAllTopicsStatus,
  setAllActiveTopics,
  setAllActiveTopicsStatus,
  setSelectedTopic,
  setSelectedTopicStatus,
} = slice.actions;

export const selectAllTopics = (state: RootState): ITopic[] | null =>
  state.topics.allTopics;
  export const selectAllTopicsStatus = (state: RootState): RequestStatusEnum | null =>
  state.topics.allTopicsStatus;
export const selectAllActiveTopics = (state: RootState): ITopic[] | null =>
  state.topics.allActiveTopics;
export const selectAllActiveTopicsStatus = (state: RootState): RequestStatusEnum | null =>
  state.topics.allActiveTopicsStatus;
export const selectSelectedTopic = (state: RootState): ITopic | null =>
  state.topics.selectedTopic;
export const selectSelectedTopicStatus = (state: RootState): RequestStatusEnum | null =>
  state.topics.selectedTopicStatus;

export default slice.reducer;