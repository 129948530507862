import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet";

import Studying from "assets/images/hero/studying.png";
import { announcements } from "helpers/announcements";
import Hero from "components/Hero";
import PublicPageLayout from "components/layouts/PublicPageLayout";
import { IBlog } from "common/types";

const Announcements = () => {
  const [latest, setLatest] = useState<IBlog[]>([]);

  useEffect(() => {
    const reversedArray = announcements.slice(0, 6).reverse();
    setLatest(reversedArray);
  }, []);

	return (
		<PublicPageLayout>
			<Helmet>
				<title>iKnow Academic Hub: Announcements</title>
				<meta name="Announcements" content="Welcome to iKnow Academic Hub!" />
			</Helmet>
			<Hero title="Announcements" image={Studying} />
			<section className="px-[30px] max-w-[1000px]">
				<div className="grid grid-cols-1 md:grid-cols-3 gap-[40px]">
					{latest.map((item) => {
						return (
							<a
								key={`announcement-${item.id}`}
								href={`/blog-entry?articleId=${item.id}`}
							>
								<img src={item.image} alt={item.title} className="mb-[10px]" />
								<p className="font-bold text-iknow-blue text-lg">
									{item.title}
								</p>
								<p className="text-[11px] font-bold text-gray-400">
									{item.datePosted.toDateString()}
								</p>
								<div
									dangerouslySetInnerHTML={{ __html: item.content }}
									className="line-clamp-3"
								/>
								<p className="hover:underline">See more</p>
							</a>
						);
					})}
				</div>
			</section>
		</PublicPageLayout>
	);
};

export default Announcements;
