import calendar from "assets/images/icons8/calendar.png";
import collab from "assets/images/icons8/collab.png";
import graduate from "assets/images/icons8/graduate.png";
import laptop from "assets/images/icons8/laptop.png";
import team from "assets/images/icons8/team.png";
import wallet from "assets/images/icons8/wallet.png";

export const whyIKnow = [
	{
		title: "Flexible Schedule",
		description:
			"Having asynchronous or pre-recorded classes allows the students to work at their most convenient times. Synchronous classes are held virtually where students can plot their schedules during their available time.",
		image: calendar,
	},
	{
		title: "Low Costs",
		description:
			"Cost advantages of the online courses include cheaper costs of enrollment and exemption from travel and meal expenses unlike the traditional face-to-face classes.",
		image: wallet,
	},
	{
		title: "More Comfortable Learning Environment",
		description:
			"Online students can complete the course at home, cafe, or library through their mobile phones, tablets, laptops, or even on their TV screens. The ability to study anywhere allows the students to work in their best suited environment.",
		image: laptop,
	},
	{
		title: "Geographic Flexibility",
		description:
			"Online courses free students from geographic barriers. This saves not only time and money but also leads to greater emotional well-being and less stress since students can live closer to their support system--their extended family members and friends.",
		image: team,
	},
	{
		title: "Career Advancement",
		description:
			"Online courses give the students an advantage when they pursue college. They can earn an online certificate upon completion of the online course.",
		image: graduate,
	},
	{
		title: "Technical Skills Improvement",
		description:
			"Learning online helps students hone the technical skills they need when they enroll in universities. These skills include the ability to use updated software and communicate online in various formats like discussion boards and videoconferencing.",
		image: collab,
	},
];
