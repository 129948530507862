import React from "react";
import PublicPageLayout from "components/layouts/PublicPageLayout";
import { Helmet } from "react-helmet";

const TermsAndConditions = () => {
    return (
        <PublicPageLayout>
            <Helmet>
                <title>iKnow Academic Hub: Terms and Conditions</title>
                <meta
                    name="Terms and Conditions"
                    content="Welcome to iKnow Academic Hub!"
                />
            </Helmet>
            <section className="section-why bg-iknow-lblue-1 !px-[50px] lg:!px-[100px] !py-[80px]">
                <p className="text-center font-bold text-[20px] lg:text-[32px] font-roboto pb-[30px]">
                    TERMS AND CONDITIONS
                </p>
                <div className="w-full max-w-[800px] !mx-auto text-iknow-blue text-[14px] lg:text-[16px]">
                    <p className="font-bold">1. Terms</p>
                    <p className="!pl-[30px] !pb-[15px]">
                        By accessing this web site, you are agreeing to be bound
                        by these web site Terms and Conditions of Use, all
                        applicable laws and regulations, and agree that you are
                        responsible for compliance with any applicable local
                        laws. If you do not agree with any of these terms, you
                        are prohibited from using or accessing this site. The
                        materials contained in this web site are protected by
                        applicable copyright and trade mark law.
                    </p>
                    <p className="font-bold">2. Use License</p>
                    <p className="!pl-[30px] !pb-[15px]">
                        1. Permission is granted to temporarily download one
                        copy of the materials (information or software) on
                        Company&apos;s web site for personal, non-commercial
                        transitory viewing only. This is the grant of a license,
                        not a transfer of title, and under this license you may
                        not:
                    </p>
                    <p className="!pl-[80px] w-[80%] !pb-[15px]">
                        1. modify or copy the materials;
                        <br />
                        2. use the materials for any commercial purpose, or for
                        any public display (commercial or non-commercial);
                        <br />
                        3. attempt to decompile or reverse engineer any software
                        contained on Company&apos;s web site;
                        <br />
                        4. remove any copyright or other proprietary notations
                        from the materials; or
                        <br />
                        5. transfer the materials to another person or
                        &apos;mirror&apos; the materials on any other server.
                    </p>
                    <p className="!pl-[30px] !pb-[15px]">
                        2. This license shall automatically terminate if you
                        violate any of these restrictions and may be terminated
                        by Company at any time. Upon terminating your viewing of
                        these materials or upon the termination of this license,
                        you must destroy any downloaded materials in your
                        possession whether in electronic or printed format.
                        <br />
                        3. iKnow Academic Hub has a 3-day 100% money back
                        guarantee policy. If you ever feel unhappy with a
                        purchase, you can request a refund within 3 days.
                        <br />
                        4. iKnow Academic Hub has the right to terminate any
                        course membership at any time, for any reason.
                        &apos;Lifetime&apos; access to a course (one time
                        purchase; subscription; or payment plan) refers to the
                        &apos;life&apos; of the course or website.
                    </p>
                    <p className="font-bold">3. Disclaimer</p>
                    <p className="!pl-[30px] !pb-[15px]">
                        The materials on Company&apos;s web site are provided
                        &apos;as is&apos;. Company makes no warranties,
                        expressed or implied, and hereby disclaims and negates
                        all other warranties, including without limitation,
                        implied warranties or conditions of merchantability,
                        fitness for a particular purpose, or non-infringement of
                        intellectual property or other violation of rights.
                        Further, Company does not warrant or make any
                        representations concerning the accuracy, likely results,
                        or reliability of the use of the materials on its
                        Internet web site or otherwise relating to such
                        materials or on any sites linked to this site.
                    </p>
                    <p className="font-bold">4. Limitations</p>
                    <p className="!pl-[30px] !pb-[15px]">
                        In no event shall Company or its suppliers be liable for
                        any damages (including, without limitation, damages for
                        loss of data or profit, or due to business
                        interruption,) arising out of the use or inability to
                        use the materials on Company&apos;s Internet site, even
                        if Company or a Company authorized representative has
                        been notified orally or in writing of the possibility of
                        such damage. Because some jurisdictions do not allow
                        limitations on implied warranties, or limitations of
                        liability for consequential or incidental damages, these
                        limitations may not apply to you.
                    </p>
                    <p className="font-bold">5. Revisions and Errata</p>
                    <p className="!pl-[30px] !pb-[15px]">
                        The materials appearing on Company&apos;s web site could
                        include technical, typographical, or photographic
                        errors. Company does not warrant that any of the
                        materials on its web site are accurate, complete, or
                        current. Company may make changes to the materials
                        contained on its web site at any time without notice.
                        Company does not, however, make any commitment to update
                        the materials.
                    </p>
                    <p className="font-bold">6. Links</p>
                    <p className="!pl-[30px] !pb-[15px]">
                        Company has not reviewed all of the sites linked to its
                        Internet web site and is not responsible for the
                        contents of any such linked site. The inclusion of any
                        link does not imply endorsement by Company of the site.
                        Use of any such linked web site is at the user&apos;s
                        own risk.
                    </p>
                    <p className="font-bold">
                        7. Site Terms of Use Modifications
                    </p>
                    <p className="!pl-[30px] !pb-[15px]">
                        Company may revise these terms of use for its web site
                        at any time without notice. By using this web site you
                        are agreeing to be bound by the then current version of
                        these Terms and Conditions of Use.
                    </p>
                    <p className="font-bold">8. Governing Law</p>
                    <p className="!pl-[30px] !pb-[15px]">
                        Any claim relating to Company&apos;s web site shall be
                        governed by the laws of the Philippines without regard
                        to its conflict of law provisions. General Terms and
                        Conditions applicable to Use of a Web Site.
                    </p>
                </div>
            </section>
        </PublicPageLayout>
    );
};

export default TermsAndConditions;
