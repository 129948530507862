import React, { useEffect, useState } from "react";

import classNames from "classnames";

import closeIcon from "assets/images/icons8/close.png";
import iKnowLogo from "assets/images/logo/iKnow_Logo.svg";
import iKnowLogoWhite from "assets/images/logo/iKnow_Logo_White.svg";
import { headerLinks } from "helpers/links";
import { setIsLoginModalOpen } from "modules/auth/slice";
import useAppDispatch from "hooks/useAppDispatch";

export const PublicNavigation = () => {
	const dispatch = useAppDispatch();
	const [scrollY, setSrollY] = useState(0);
	const [isMenuOpen, setMenuOpen] = useState(false);

	const handleBurgerClick = () => {
		setMenuOpen(!isMenuOpen);
	};

	useEffect(() => {
		const handleScroll = () => {
			const posY = window.scrollY;
			setSrollY(posY);
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<div
			className={classNames(
				`z-[99999] sticky top-0 bg-white text-iknow-blue h-[80px] lg:h-[160px] transition-all duration-500 items-center flex`,
				{
					"h-screen": isMenuOpen,
					"!h-[80px] !bg-iknow-blue !text-white": scrollY > 0,
				}
			)}
		>
			<a
				href="/"
				className={classNames(
					"absolute left-[25px] top-[15px] cursor-pointer block lg:hidden duration-500",
					{
						"opacity-0": isMenuOpen,
					}
				)}
			>
				<img
					src={scrollY > 0 ? iKnowLogoWhite : iKnowLogo}
					className="object-contain my-auto h-[50px]"
					alt="iKnow Logo"
				/>
			</a>
			<div
				className="absolute right-[25px] top-[20px] cursor-pointer block lg:hidden"
				onClick={handleBurgerClick}
			>
				<div className="p-3 rounded-md w-fit items-center flex flex-col">
					<div
						className={`w-5 h-[2px] ${
							scrollY > 0 ? "bg-white" : "bg-iknow-blue"
						}`}
					/>
					<div
						className={`w-3 my-1 h-[2px] ${
							scrollY > 0 ? "bg-white" : "bg-iknow-blue"
						}`}
					/>
					<div
						className={`w-5 h-[2px] ${
							scrollY > 0 ? "bg-white" : "bg-iknow-blue"
						}`}
					/>
				</div>
			</div>
			<div className="hidden lg:flex flex-row px-[50px] content-center w-full">
				<a href="/" className="my-auto">
					<img
						src={scrollY > 0 ? iKnowLogoWhite : iKnowLogo}
						className="object-contain my-auto h-[75px]"
						alt="iKnow Logo"
					/>
				</a>
				<div className="my-auto ml-auto flex flex-row gap-[40px] font-bold">
					{headerLinks.map((link) => {
						if (!link.hidden) {
							return (
								<a
									key={link.displayText}
									href={link.link}
									className="group transition duration-300 capitalize"
								>
									{link.displayText}
									<span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-1 bg-iknow-yellow"></span>
								</a>
							);
						} else {
							return null;
						}
					})}
					<a
						onClick={() => dispatch(setIsLoginModalOpen(true))}
						className="group transition duration-300 capitalize"
					>
						Sign In
						<span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-1 bg-iknow-yellow"></span>
					</a>
				</div>
			</div>
			{/* Mobile menu */}
			<div
				className={classNames(
					"flex flex-col items-center fixed inset-0 bg-iknow-blue font-bold text-[18px] transition-all h-0 duration-500 opacity-0",
					{
						"h-full opacity-100 !duration-1000": isMenuOpen,
					}
				)}
			>
				<div
					className="absolute text-white font-light text-[28px] right-[25px] top-[20px]"
					onClick={handleBurgerClick}
				>
					<img src={closeIcon} alt="Close Menu Icon" className="!h-[20px]" />
				</div>
				<a href="/" className="mt-[30px]">
					<img
						src={iKnowLogoWhite}
						className="object-contain h-[75px]"
						alt="iKnow Logo"
					/>
				</a>
				<div className="mt-[100px] flex flex-col justify-center items-center gap-[50px]">
					{headerLinks.map((link) => {
						if (!link.hidden) {
							return (
								<a
									key={link.displayText}
									href={link.link}
									className="text-white hover:text-iknow-yellow uppercase"
								>
									{link.displayText}
									<span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-1 bg-iknow-yellow"></span>
								</a>
							);
						} else {
							return null;
						}
					})}
					<p
						onClick={() => dispatch(setIsLoginModalOpen(true))}
						className="text-white hover:text-iknow-yellow uppercase"
					>
						Sign In
						<span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-1 bg-iknow-yellow"></span>
					</p>
				</div>
			</div>
		</div>
	);
};

export default PublicNavigation;