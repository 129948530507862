import React, { useState } from "react";

import { Collapse, initTE } from "tw-elements";
import classNames from "classnames";

import { faqs } from "./constants";

initTE({ Collapse });

const FaqsList = () => {
	const [searchInput, setSearchInput] = useState("");
	const [openedFaq, setOpenedFaq] = useState<string | null>(null);

	const openFaq = (index: number) => {
		if (openedFaq === `a${index}`) {
			setOpenedFaq(null);
		} else {
			setOpenedFaq("a" + index);
		}
	};

	return (
		<div className="">
			<div className="relative mx-auto mb-[60px] flex w-full md:w-[500px] items-center">
				<input
					type="text"
					placeholder="Search FAQS"
					value={searchInput}
					onChange={(e) => {
						setSearchInput(e.target.value);
					}}
					className={`peer input input-bordered w-full px-[25px] py-[10px] border-iknow-blue border-2 rounded-[10px]`}
				/>

				<span className="absolute right-[40px]  grid h-[90%] w-[40px] place-items-center rounded-lg bg-transparent text-iknow-blue font-bold">
					SEARCH
				</span>
			</div>
			<div id="faqsAccordion" className="w-[800px] mx-[60px]">
				{faqs
					.filter(
						(faq) =>
							(faq.q
								.toLocaleLowerCase()
								.includes(searchInput.toLocaleLowerCase()) &&
								faq) ||
							(faq.a.includes(searchInput.toLocaleLowerCase()) && faq)
					)
					.map((faq, i: any) => {
						return (
							<div key={`faq-${i}`} className="pt-[20px] w-full">
								<button
									id={`q${i}`}
									className="bg-iknow-lblue-2 px-[20px] py-[20px] font-bold w-full border-none rounded-none text-left !z-[9999]"
									onClick={() => openFaq(i)}
								>
									{faq.q}
								</button>
								<div
									id={`a${i}`}
									className={classNames(
										"pointer-events-none !z-[20] ease-in-out w-full origin-top bg-white p-[20px] transition-all",
										{
											"translate-y-0 h-auto duration-1000 ":
												openedFaq === `a${i}`,
											"opacity-0 h-0 duration-200 ": openedFaq !== `a${i}`,
										}
									)}
								>
									{faq.a.map((a, i) => {
										return (
											<p key={i} className="">
												{a}
											</p>
										);
									})}
								</div>
							</div>
						);
					})}
			</div>
		</div>
	);
};

export default FaqsList;
