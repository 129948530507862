import React from "react";

import email from "assets/images/icons8/yellow-email.png";
import phone from "assets/images/icons8/yellow-phone.png";
import facebook from "assets/images/socialicons/fb.png";
import group from "assets/images/socialicons/group.png";
import instagram from "assets/images/socialicons/insta.png";
import tiktok from "assets/images/socialicons/tiktok.png";
import web from "assets/images/socialicons/web.png";
import youtube from "assets/images/socialicons/youtube.png";
import { contactInformation, socialLinks } from "helpers/links";
import { ILinks } from "common/types";

const Footer = () => {
    return (
        <footer className="bg-iknow-blue text-white min-h-[200px] !pt-[50px] !px-[30px] lg:!px-[100px]">
            <div className="lg:flex">
                <div className="basis-1/2 mailing font-roboto text-[14px] !pt-[10px] flex flex-col lg:!pr-[50px] !mb-[30px] text-center lg:text-left lg:block">
                    <p className="font-bold text-iknow-yellow text-[20px] !pb-[10px]">
                        iKNOW ACADEMIC HUB
                    </p>
                    <div className="flex">
                        <p className="">
                            iKnow Academic Hub (iKnow) is a start-up,
                            small-sized website offering online courses for
                            College Entrance Test (CET) Reviews and developing
                            Learning Management System (LMS) to supplement the
                            ongoing online/hybrid classes and to develop mastery
                            of the high school and college curricula. Students
                            can follow along with the curriculum of the course
                            they are enrolled in using their mobile phones,
                            tablets, and laptops through their registered
                            account at the iKnow Academic Hub website.
                            <br />
                            <br />
                            iKnow aims to nurture the preparation journey of
                            every aspirant in their most crucial years of their
                            education through its unique pedagogy of academic
                            mentoring.
                        </p>
                    </div>
                </div>
                <div className="basis-1/4 site-links font-roboto text-[14px] !pt-[10px] flex flex-col text-center lg:text-left !mb-[30px]">
                    <p className="font-bold text-iknow-yellow text-[20px] !pb-[10px]">
                        USEFUL LINKS
                    </p>
                    <div className="grid grid-cols-1 gap-[10px]">
                        <a
                            href="/frequently-asked-questions"
                            className="font-bold !text-white !visited:text-white"
                        >
                            FREQUENTLY ASKED QUESTIONS
                        </a>
                        <a
                            href="/terms-and-conditions"
                            className="font-bold !text-white !visited:text-white"
                        >
                            TERMS AND CONDITIONS
                        </a>
                        <a
                            href="/privacy-policy"
                            className="font-bold !text-white !visited:text-white"
                        >
                            PRIVACY POLICY
                        </a>
                        <a
                            href="/about-us"
                            className="font-bold !text-white !visited:text-white"
                        >
                            ABOUT US
                        </a>
                    </div>
                </div>
                <div className="basis-1/4 social-links font-roboto text-[14px] !pt-[10px] flex flex-col text-center lg:text-left gap-[10px]">
                    <p className="font-bold text-iknow-yellow text-[20px]">
                        CONTACT
                    </p>
                    {contactInformation.map((contact: ILinks) => {
                        if (!contact.hidden) {
                            return (
                                <a
                                    key={contact.displayText}
                                    href={contact.link}
                                    target="blank"
                                    className="flex flex-row !mx-auto lg:!mx-0 lg:!mr-auto"
                                >
                                    <img
                                        src={
                                            contact.link
                                                .toLocaleLowerCase()
                                                .includes("mail")
                                                ? email
                                                : contact.link
                                                      .toLocaleLowerCase()
                                                      .includes("tel")
                                                ? phone
                                                : web
                                        }
                                        alt={contact.displayText}
                                        className="!max-w-[20px] !mr-[10px]"
                                    />
                                    <p className="self-center">
                                        {contact.displayText}
                                    </p>
                                </a>
                            );
                        } else {
                            return null;
                        }
                    })}
                    <div className="hidden">
                        <p className="font-bold text-iknow-yellow text-[20px] !pb-[10px] !pt-[40px]">
                            MAILING
                        </p>
                        <p>
                            Sign up for our mailing list to get latest updates
                            and offers.
                        </p>
                        <div className="!mt-[20px] flex !mx-auto lg:!mx-0 lg:!mr-auto">
                            <div className="basis-3/5">test</div>
                            <button className="basis-1/5 btn-yellow text-[12px] !px-[8px] font-bold">
                                SUBSCRIBE
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full !my-[50px] !mx-auto h-[1px] bg-iknow-yellow"></div>
            <div className="w-full grid grid-cols-1 lg:grid-cols-2 !mx-auto !pb-[50px]">
                <div className="w-max grid grid-cols-5 gap-[30px] lg:gap-[15px] !mb-[15px] lg:!mb-[0px] !mx-auto lg:!mx-0">
                    {socialLinks.map((social: ILinks) => {
                        if (!social.hidden) {
                            return (
                                <a
                                    key={social.displayText}
                                    href={social.link}
                                    target="blank"
                                >
                                    <img
                                        src={
                                            social.displayText
                                                .toLocaleLowerCase()
                                                .includes("youtube")
                                                ? youtube
                                                : social.displayText
                                                      .toLocaleLowerCase()
                                                      .includes("facebook")
                                                ? facebook
                                                : social.displayText
                                                      .toLocaleLowerCase()
                                                      .includes("group")
                                                ? group
                                                : social.displayText
                                                      .toLocaleLowerCase()
                                                      .includes("tiktok")
                                                ? tiktok
                                                : social.displayText
                                                      .toLocaleLowerCase()
                                                      .includes("instagram")
                                                ? instagram
                                                : web
                                        }
                                        alt={social.displayText}
                                        className="!max-w-[30px]"
                                    />
                                </a>
                            );
                        } else {
                            return null;
                        }
                    })}
                </div>
                <p className="basis-1/2 font-roboto text-[10px] lg:text-[16px] text-center lg:text-right">
                    &#169; Copyright iKnow Academic Hub 2023 | All Rights
                    Reserved
                </p>
            </div>
        </footer>
    );
};

export default Footer;
