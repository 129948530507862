import React, { useEffect, useState } from "react";

import MetaDecorator from "./MetaDecorator";

interface BlogMetaDecoratorProps {
	title: string;
	description: string;
	metaImage: string;
	datePublished?: string;
};

const BlogMetaDecorator = ({
	title,
	description,
	metaImage,
	datePublished
}: BlogMetaDecoratorProps) => {
	const [url, setUrl] = useState("");

	useEffect(() => {
		import(`../../assets/images/announcements/${metaImage}`).then(
			(imageLink) => {
				setUrl(imageLink.default);
			}
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [metaImage]);

	return (
		<>
			<MetaDecorator
				imageUrl={url}
				title={title}
				description={description}
				type="article"
				datePublished={datePublished}
			/>
			
		</>
	);
};

export default BlogMetaDecorator;
