import React, { useEffect, useState } from "react";
import { useEffectOnce } from "react-use";

import { IBlog } from "common/types";
import { announcements } from "helpers/announcements";
import BlogMetaDecorator from "components/meta/BlogMetaDecorator";
import PublicPageLayout from "components/layouts/PublicPageLayout";

const BlogEntry = () => {
	const queryParameters = new URLSearchParams(location.search);
	const articleId = Number(queryParameters.get("articleId"));
	const [article, setArticle] = useState<IBlog | null>(null);
	const [latest, setLatest] = useState<(IBlog | null)[]>([]);

	useEffect(() => {
		const reversedArray = announcements
			.slice()
			.reverse()
			.map((item, index) => {
				if (index < 7 && item.id !== article?.id) {
					return item;
				} else {
					return null;
				}
			});
		setLatest(reversedArray);
	}, [article]);

	useEffectOnce(() => {
		const foundArticle = announcements.find((item) => {
			if (item.id === articleId) return item;
			else return null;
		});
		if (foundArticle) setArticle(foundArticle);
	});

	return (
		<PublicPageLayout>
			{article && (
				<BlogMetaDecorator
					title={article?.title}
					description={article?.description}
					metaImage={article?.metaImage}
				/>
			)}
			<aside className="my-[90px] mx-auto w-full lg:max-w-[1000px]">
				<section className="px-[30px]">
					{article ? (
						<div className="">
							<p className="font-bold text-iknow-blue text-[24px] md:text-[36px] w-[90%]">
								{article.title}
							</p>
							<p className="text-[12px] font-bold text-gray-400">
								{article.datePosted.toDateString()}
							</p>
							<img
								src={article.image}
								alt={article.title}
								className="my-[30px] w-full md:max-w-[600px] mx-auto"
							/>
							<div
								dangerouslySetInnerHTML={{ __html: article.content }}
								className=""
							/>
							<p className="mt-[15px] text-[12px] font-bold text-gray-400">
								{article.tags?.map((tag, index) => {
									return (
										<span key={`tag-${index}`}>
											#{tag}
											{article.tags && index !== article.tags.length - 1
												? ", "
												: ""}
										</span>
									);
								})}
							</p>
						</div>
					) : (
						<div>Article not found.</div>
					)}
					<div></div>
				</section>
				<section className="mx-auto mt-[60px] md:mt-[100px] px-[30px]">
					<p className="font-bold text-[36px]">Read More Articles</p>
					<div
						id="Latest News"
						className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-[40px] mx-auto mt-[100px]"
					>
						{latest.map((item) => {
							if (item) {
								return (
									<a
										key={`announcement-${item.id}`}
										href={`/blog-entry?articleId=${item.id}`}
									>
										<img
											src={item.image}
											alt={item.title}
											className="mb-[10px]"
										/>
										<p className="font-bold text-iknow-blue text-lg">
											{item.title}
										</p>
										<p className="text-[11px] font-bold text-gray-400">
											{item.datePosted.toDateString()}
										</p>
										<div
											dangerouslySetInnerHTML={{ __html: item.content }}
											className="line-clamp-3"
										/>
										<p className="hover:underline">See more</p>
									</a>
								);
							} else {
								return null;
							}
						})}
					</div>
				</section>
			</aside>
		</PublicPageLayout>
	);
};

export default BlogEntry;
