export enum RequestStatusEnum {
    SUCCESS = "success",
    FAILED = "failed",
    ERROR = "error",
    PENDING = "pending",
    CANCELLED = "cancelled",
}

export interface ILinks {
    displayText: string;
    link: string;
    hidden: boolean;
}

export interface IContent {
    title: string;
    subtitle?: string;
    description: string;
    image: string;
}

export interface IBlog {
    id: number;
    title: string;
    subtitle?: string;
    description: string;
    content: string;
    image: string;
    metaImage: string;
    externalLink?: string;
    tags?: string[] | null;
    datePosted: Date;
}

export interface ITestimonial {
    name: string;
    school?: string;
    message: string;
    picture: string;
}

export interface IUser {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    isGuest: boolean;
    emailSubscription: boolean;
    dateCreated: string;
}

export interface ICourse {
    id: number;
    courseName: string;
    courseCode: string;
    courseDescription: string;
    price: number;
    enrollmentLimit: number;
    isActive: boolean;
    dateCreated: string;
}

export interface ITopic {
    id: number;
    topicName: string;
    courseId: string;
    topicDescription: string;
    isActive: boolean;
    dateCreated: string;
}

export interface ISubscription {
    id: number;
    courseName: string;
    courseDescription: string;
    subscriptionDate: string;
    expirationDate: string;
    price: number;
}

export interface ILesson {
    id: number;
    lessonName: string;
    topicId: string;
    lessonDescription: string;
    videoLink: string;
    isActive: boolean;
    dateCreated: string;
}
