import React from "react";

import { PropagateLoader } from "react-spinners";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import * as Yup from "yup";

import closeIcon from "assets/images/icons8/close.png";
import { InvalidText } from "components/Typography";
import {
    selectLoginError,
    selectPendingLogin,
    setIsLoginModalOpen,
} from "modules/auth/slice";
import ACTIONS from "modules/rootActions";
import useAppDispatch from "hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";
import CustomInput from "components/CustomInput";

type FormValues = {
    email: string;
    password: string;
};

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .required("Email is required")
        .email("Invalid email format"),
    password: Yup.string().required("Password is required"),
});

const LoginModal = () => {
    const dispatch = useAppDispatch();
    const isPending = useAppSelector(selectPendingLogin);
    const error = useAppSelector(selectLoginError);

    const formOptions = {
        resolver: yupResolver(validationSchema),
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormValues>(formOptions);

    const onSubmit: SubmitHandler<FormValues> = ({ email, password }) => {
        dispatch({
            type: ACTIONS.LOGIN_ACCOUNT,
            payload: {
                email,
                password,
            },
        });
    };

    return (
        <div className="fixed z-[999999] h-full w-full bg-[#000000aa] flex">
            <section className="my-auto mx-4 w-full md:w-[600px] md:mx-auto">
                <div className="relative p-8 md:p-20 rounded-[20px] bg-iknow-lblue-1">
                    <div
                        className="bg-[#00000044] w-fit absolute right-8 top-8 p-2 rounded-full"
                        onClick={() => dispatch(setIsLoginModalOpen(false))}
                    >
                        <img
                            src={closeIcon}
                            alt="Close Menu Icon"
                            className="!h-[20px]"
                        />
                    </div>
                    <p className="text-center font-bold text-[24px] md:text-[36px] mb-8 text-iknow-blue">
                        Log In
                    </p>
                    <form
                        className="grid grid-cols-1 gap-[10px]"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <CustomInput
                            type="text"
                            name="email"
                            placeholder="Email address"
                            required={true}
                            error={errors?.email}
                            register={register}
                        />
                        <InvalidText>{errors.email?.message}</InvalidText>
                        <CustomInput
                            type="password"
                            name="password"
                            placeholder="Password"
                            required={true}
                            error={errors?.password}
                            register={register}
                        />
                        {typeof error === "string" ? (
                            !error.includes("inactive") && (
                                <InvalidText>{error}</InvalidText>
                            )
                        ) : (
                            <InvalidText>
                                {errors.password?.message}
                            </InvalidText>
                        )}

                        <button
                            type="submit"
                            disabled={isPending}
                            className="py-[10px] rounded-full bg-iknow-yellow-1 text-iknow-blue font-bold disabled:bg-grey-400 hover:bg-gradient-to-l from-iknow-yellow to-iknow-yellow-1"
                        >
                            SUBMIT
                        </button>
                    </form>
                    <div className="mt-[30px] mx-auto text-[12px] flex flex-col justify-center items-center gap-3">
                        <p className="text-iknow-blue">
                            <a href="/register" className="font-bold">
                                Forgot Your Password?
                            </a>
                        </p>
                        <p className="text-iknow-blue">
                            <a href="/register" className="font-bold">
                                Sign Up
                            </a>
                        </p>
                    </div>
                </div>
            </section>
            {isPending && (
                <div className="z-[99999999] fixed top-0 left-0 w-[100vw] h-[100vh]">
                    <div className="relative w-full h-full">
                        <div className="absolute w-full h-full bg-black opacity-[50%]"></div>
                        <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-fit h-fit">
                            <PropagateLoader color="#ffffff" size={20} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LoginModal;
