import { takeLatest } from 'redux-saga/effects';
import {
  SAGA_ACTIONS,
  loginAccount,
  logoutAccount,
  registerAccount
} from './sagas';

const {
  LOGIN_ACCOUNT,
  LOGOUT_ACCOUNT,
  REGISTER_ACCOUNT
} = SAGA_ACTIONS;

const actions = [
  takeLatest(LOGIN_ACCOUNT, loginAccount),
  takeLatest(LOGOUT_ACCOUNT, logoutAccount),
  takeLatest(REGISTER_ACCOUNT, registerAccount),
];

export default actions;
