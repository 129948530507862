import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import { ISubscription, IUser, RequestStatusEnum } from 'common/types';


type InitialState = {
  user: IUser | null;
  userStatus: RequestStatusEnum | null;
  subscriptions: ISubscription[] | null;
  changeStatus: RequestStatusEnum | null;
}

const initialState: InitialState = {
  user: null,
  userStatus: null,
  subscriptions: null,
  changeStatus: null,
}

export const slice = createSlice({
  name: 'me',
  initialState,
  reducers: {
    reset: () => initialState,
    setUser: (state, action: PayloadAction<IUser | null>) => {
      state.user = action.payload;
    },
    setUserStatus: (state, action: PayloadAction<RequestStatusEnum | null>) => {
      state.userStatus = action.payload;
    },
    setSubscriptions: (state, action: PayloadAction<ISubscription[] | null>) => {
      state.subscriptions = action.payload;
    },
    setChangeStatus: (state, action: PayloadAction<RequestStatusEnum | null>) => {
      state.changeStatus = action.payload;
    },
  }
});

export const {
  reset,
  setUser,
  setUserStatus,
  setSubscriptions,
  setChangeStatus,
} = slice.actions;

export const selectUser = (state: RootState): IUser | null =>
  state.me.user;
export const selectUserPending = (state: RootState): RequestStatusEnum | null =>
  state.me.userStatus;
export const selectSubscriptions = (state: RootState): ISubscription[] | null =>
  state.me.subscriptions;
export const selectChangePending = (state: RootState): RequestStatusEnum | null =>
  state.me.changeStatus;

export default slice.reducer;