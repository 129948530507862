import React, { ReactNode, useEffect } from "react";

import { selectIsLoginModalOpen, setLoginError } from "modules/auth/slice";
import useAppSelector from "hooks/useAppSelector";
import LoginModal from "components/LoginModal";

interface Props {
    children: ReactNode;
}

const PageLayout = ({ children }: Props) => {
    const isLoginModalOpen = useAppSelector(selectIsLoginModalOpen);

    useEffect(() => {
        if (!isLoginModalOpen) setLoginError(null);
    }, [isLoginModalOpen]);

    return (
        <main>
            {isLoginModalOpen && <LoginModal />}
            {children}
        </main>
    );
};

export default PageLayout;
