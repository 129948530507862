import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import { RequestStatusEnum } from 'common/types';


type InitialState = {
  pendingLogin: boolean;
  loginError: any;
  isLoginModalOpen: boolean;
  pendingLogout: boolean;
  error: any;
  registerStatus: RequestStatusEnum | null;
}

const initialState: InitialState = {
  pendingLogin: false,
  loginError: null,
  isLoginModalOpen: false,
  pendingLogout: false,
  error: null,
  registerStatus: null,
}

export const slice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		reset: () => initialState,
		setPendingLogin: (state, action: PayloadAction<boolean>) => {
			state.pendingLogin = action.payload;
		},
		setLoginError: (state, action: PayloadAction<any>) => {
			state.loginError = action.payload;
		},
		setIsLoginModalOpen: (state, action: PayloadAction<boolean>) => {
			state.isLoginModalOpen = action.payload;
		},
		setPendingLogout: (state, action: PayloadAction<boolean>) => {
			state.pendingLogout = action.payload;
		},
		setError: (state, action: PayloadAction<any>) => {
			state.error = action.payload;
		},
		setRegisterStatus: (
			state,
			action: PayloadAction<RequestStatusEnum | null>
		) => {
			state.registerStatus = action.payload;
		},
	},
});

export const {
  reset,
  setPendingLogin,
  setLoginError,
  setIsLoginModalOpen,
  setPendingLogout,
  setError,
  setRegisterStatus
} = slice.actions;

export const selectPendingLogin = (state: RootState): boolean =>
  state.auth.pendingLogin;
export const selectLoginError = (state: RootState): any =>
  state.auth.loginError;
export const selectIsLoginModalOpen = (state: RootState): boolean =>
  state.auth.isLoginModalOpen;
export const selectPendingLogout = (state: RootState): boolean =>
  state.auth.pendingLogout;
export const selectError = (state: RootState): any =>
  state.auth.error;
export const selectRegisterStatus = (state: RootState): RequestStatusEnum | null =>
  state.auth.registerStatus;

export default slice.reducer;
