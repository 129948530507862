import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { testimonials } from "helpers/testimonials";

const Testimonials = () => {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        arrows: false,
        autoplaySpeed: 4000,
        pauseOnHover: true,
    };

    return (
        <section
            id="testimonials"
            className="section-testimonials bg-iknow-yellow text-iknow-blue min-h-[60vh] !px-[40px] lg:!px-[120px] !py-[80px]"
        >
            <p className="text-center font-bold font-roboto text-[24px] lg:text-[48px] mg-[20px] lg:mb-[40px]">
                What our students say
            </p>
            <div className="w-full md:w-[80%] mx-auto max-w-[800px] ">
                <Slider {...settings}>
                    {testimonials.map((item) => {
                        return (
                            <div
                                key={item.name}
                                className="flex flex-col h-full"
                            >
                                <div className="flex items-center">
                                    <img
                                        src={item.picture}
                                        alt=""
                                        className="m-[20px] w-[60px]"
                                    />
                                    <div>
                                        <p className="font-black md:text-[20px] font-roboto">
                                            {item.name}
                                        </p>
                                        <p className="font-bold font-roboto text-[10px] md:text-[14px] opacity-70">
                                            {item.school}
                                        </p>
                                    </div>
                                </div>
                                <p className="text-center font-roboto text-[14px] md:text-[18px] px-5">
                                    &quot;{item.message}&quot;
                                </p>
                            </div>
                        );
                    })}
                </Slider>
            </div>
        </section>
    );
};

export default Testimonials;
