import React from "react";

import { Helmet } from "react-helmet";

const metadecorator = require("./metadecorator.json");

interface MetaDecoratorProps {
	title: string,
	description: string,
	imageUrl: string,
	type?: string,
	datePublished?:string
};

const MetaDecorator = ({
	title,
	description,
	imageUrl,
	type,
	datePublished
}: MetaDecoratorProps) => {
	
	const articleStructuredData = {
		"@context": "https://schema.org/",
		"@type": "Article",
		headline: title,
		description,
		image: metadecorator.hostname + imageUrl,
		datePublished,
		author: {
			"@type": "Organisation",
			name: "iKnow Academic Hub",
			url: "https://www.iknowacademichub.com",
		},
	};
	
	return (
	<>
		<Helmet>
			<title>{title}</title>

			{type && <meta name="og:type" content={type} />}
			<meta name="title" property="og:title" content={title} />
			<meta name="description" property="og:description" content={description} />
			<meta
				name="image"
				property="og:image"
				content={metadecorator.hostname + imageUrl}
			/>
			<meta
				name="og:url"
				content={
					metadecorator.hostname +
					window.location.pathname +
					window.location.search
				}
			/>
			<meta property="twitter:card" content="summary_large_image" />
			<meta
				property="twitter:url"
				content={
					metadecorator.hostname +
					window.location.pathname +
					window.location.search
				}
			/>
			<meta property="twitter:title" content="iKnow Academic Hub" />
			<meta property="twitter:description" content={description} />
			<meta
				property="twitter:image"
				content={metadecorator.hostname + imageUrl}
			/>
		</Helmet>
		<script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(articleStructuredData),
        }}
      />
	</>
)};

export default MetaDecorator;
