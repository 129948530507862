import { takeLatest } from 'redux-saga/effects';
import {
  SAGA_ACTIONS,
  fetchAllActiveLessons,
  fetchAllLessons,
  fetchLessonDetails,
} from './sagas';

const {
  FETCH_ALL_ACTIVE_LESSONS,
  FETCH_ALL_LESSONS,
  FETCH_LESSON_DETAILS
} = SAGA_ACTIONS;

const actions = [
  takeLatest(FETCH_ALL_ACTIVE_LESSONS, fetchAllActiveLessons),
  takeLatest(FETCH_ALL_LESSONS, fetchAllLessons),
  takeLatest(FETCH_LESSON_DETAILS, fetchLessonDetails),
];

export default actions;
