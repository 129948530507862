import React, { useEffect } from "react";

import { gsap } from "gsap";

import { IContent } from "common/types";

interface Props {
	index: number;
	item: IContent;
}

const Item = ({ item, index }: Props) => {
	const modulo = index % 2;
	const featureTextId = `feature-${index}`;

	useEffect(() => {
		const ftl = gsap.timeline({
			scrollTrigger: {
				trigger: `#${featureTextId}`,
				start: "top bottom",
				markers: false,
			},
			duration: 0.75,
		});
		ftl.fromTo(
			`#${featureTextId}`,
			{
				opacity: 0,
			},
			{
				opacity: 1,
			}
		);
	}, [featureTextId]);

	return (
		<div
			id={featureTextId}
			className={`flex flex-col w-full justify-center items-center md:gap-[45px] ${
				modulo !== 0 ? "md:flex-row-reverse" : "md:flex-row"
			}`}
		>
			<div className="w-[100px] h-fit flex justify-center relative ss:w-full">
				<img src={item.image} />
			</div>
			<div
				className={`w-full lg:max-w-[500px] text-center ${
					modulo !== 0 ? "md:text-right" : "md:text-left"
				}`}
			>
				<p className="text-[20px] font-bold">{item.title}</p>
				<p className="text-[12px] lg:text-[16px]">{item.description}</p>
			</div>
		</div>
	);
};

export default Item;
