import adeluna from "assets/images/testimonials/adeluna.png";
import bbayaborda from "assets/images/testimonials/bbayaborda.png";
import dcastillo from "assets/images/testimonials/dcastillo.png";
import mtalaue from "assets/images/testimonials/mtalaue.png";

export const testimonials = [
	{
		name: "Azequiel de Luna",
		school: "Ateneo de Manila University Batch 2022 Management Engineering",
		message:
			"Once you hear Sir Mon speak, you will understand everything that he is saying. His sessions never felt formal and stressful. It was always a fun time while also learning the lessons in an efficient manner. I owe him my spot at the Ateneo de Manila University and also my future career. I highly recommend iKnow Academic Hub and take him as your tutor.",
		picture: adeluna,
	},
	{
		name: "Bhey Bayaborda",
		school: "De La Salle University BS Interdisciplinary Business Studies",
		message:
			"During the tutorial sessions, I noticed that Sir Mon was very diligent in explaining theories and mathematical equations. He made sure that we understand each questions before we move on to the next one. Because of this, I was able to pass the De La Salle University college entrance examination. Until today, I am very grateful that I attended his tutorial services. I am blessed to know someone with good teaching skills and work ethics. Now that he is into online tutorials, I know that he will be able to teach more and more students.",
		picture: bbayaborda,
	},
	{
		name: " Ma. Justine Antonette Talaue",
		school:
			"Cum Laude, Enderun Colleges, Inc. Batch 2022 Business Administration",
		message:
			"The lessons were taught at the eye-level so the students will deeply understand. Sir Mon even gave me analogies and mnemonics that made me remember the topics even until now. I gained enough confidence to pass the entrance exams within a short period of time.",
		picture: mtalaue,
	},
	{
		name: "Rowena Denise Castillo",
		school: "Ateneo de Manila University BS Psychology",
		message:
			"Sir Mon can explain even the most complex concepts in an understandable manner. Every session you take with him is everything except boring. He also knows how to keep your motivation high while keeping things real. Thanks to Sir Mon and his lessons at iKnow Academic Hub, I aced all my Statistics and Mathematics up until my stay in the Ateneo.",
		picture: dcastillo,
	},
];