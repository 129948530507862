import React from "react";

interface Props {
  image: string;
  title: string
}

const Hero  = ({ image, title } : Props) => {

  return (
    <div className="w-full h-fit flex mb-[30px] lg:mb-[110px] bg-yellow-500">
      <div className="pl-[30px] sm:pl-[60px] lg:pl-[110px] my-auto mr-auto">
        <p className="font-signika font-bold text-[28px] sm:text-[40px] lg:text-[72px] text-iknow-blue leading-[1]">
          {title}
        </p>
      </div>
      <div>
        <img src={image} />
      </div>
    </div>
  )
}

export default Hero;