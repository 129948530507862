import React, { useEffect, useState } from "react";
import useAppSelector from "hooks/useAppSelector";
import {
    selectChangePending,
    selectSubscriptions,
    selectUser,
    setChangeStatus,
} from "modules/me/slice";
import { SubmitHandler, useForm } from "react-hook-form";
import moment from "moment";
import * as _ from "lodash";
import useAppDispatch from "hooks/useAppDispatch";
import ACTIONS from "modules/rootActions";
import { selectError, setError } from "modules/auth/slice";
import { getToken } from "common/helpers";
import { RequestStatusEnum } from "common/types";
import Swal from "sweetalert2";

type FormValues = {
    currentPassword: string;
    newPassword: string;
};

const MyAccount = () => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);
    const subscriptions = useAppSelector(selectSubscriptions);
    const error = useAppSelector(selectError);
    const changeStatus = useAppSelector(selectChangePending);
    const [openChangePassord, setOpenChangePassword] = useState(false);

    function isStrongPassword(password: string) {
        // Minimum 7 characters, at least one uppercase, one lowercase, and one number
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{7,}$/;
        return passwordRegex.test(password);
    }

    const { register, handleSubmit, clearErrors, reset } =
        useForm<FormValues>();

    const onPasswordSubmit: SubmitHandler<FormValues> = ({
        currentPassword,
        newPassword,
    }) => {
        if (isStrongPassword(newPassword)) {
            dispatch({
                type: ACTIONS.UPDATE_USER_PASSWORD,
                payload: {
                    currentPassword,
                    newPassword,
                },
            });
        } else {
            setError({
                password:
                    "Password must contain both lower and upper case letters with at least one number and minimum of 7 characters.",
            });
        }
    };

    useEffect(() => {
        if (changeStatus === RequestStatusEnum.SUCCESS) {
            dispatch({
                type: ACTIONS.FETCH_USER_INFO,
                payload: { accessToken: getToken() },
            });
            setOpenChangePassword(false);
            clearErrors();
            reset();
            Swal.fire({
                title: '<p class="font-bold font-q">Success!!!</p>',
                icon: "success",
                iconColor: "#1f3462",
                html: "Your information<br/>&nbsp;has been updated succesfully.",
                showCloseButton: false,
                showCancelButton: false,
                focusConfirm: false,
                allowEscapeKey: true,
                allowOutsideClick: true,
                confirmButtonColor: "#1f3462",
                confirmButtonText: "CLOSE",
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch(setChangeStatus(null));
                }
            });
        }
    }, [changeStatus, clearErrors, dispatch, reset]);

    return (
        <section className="px-0 md:px-[120] font-roboto grid grid-cols-1 gap-[30px]">
            {user && (
                <div className="flex flex-col gap-[5px] w-full p-[25px] md:p-[60px] bg-gray-600 rounded-[15px] text-white text-[14px] md:text-[16px]">
                    <p className="font-bold text-[20px] mb-[20px]">
                        ACCOUNT DETAILS
                    </p>
                    <p>
                        Name:&nbsp;
                        <span className="font-bold">
                            {user.firstName}&nbsp;{user.lastName}
                        </span>
                    </p>
                    <p>
                        Email Address:&nbsp;
                        <span className="font-bold">{user.email}</span>
                    </p>
                    <p>
                        Member since:&nbsp;
                        <span className="font-bold">
                            {moment(user?.dateCreated).format("DD MMMM YYYY")}
                        </span>
                    </p>
                    <div>
                        {openChangePassord ? (
                            <form
                                action="post"
                                className="inline"
                                onSubmit={handleSubmit(onPasswordSubmit)}
                            >
                                <input
                                    type="password"
                                    placeholder="Current password"
                                    className="focus:outline-none bg-transparent border-b-[1px] border-iknow-yellow min-w-[200px] font-bold text-iknow-yellow mr-[25px]"
                                    {...register("currentPassword")}
                                />
                                <input
                                    type="password"
                                    placeholder="New password"
                                    className="focus:outline-none bg-transparent border-b-[1px] border-iknow-yellow min-w-[200px] font-bold text-iknow-yellow"
                                    {...register("newPassword")}
                                />
                                <button
                                    type="submit"
                                    className="ml-[10px] text-[11px] hover:text-iknow-yellow"
                                >
                                    SUBMIT
                                </button>
                                <button
                                    className="mx-[10px] text-[11px] hover:text-iknow-yellow"
                                    onClick={() => setOpenChangePassword(false)}
                                >
                                    CANCEL
                                </button>
                                {error && (
                                    <span className="text-[12px] text-yellow-200 self-start">
                                        {error.password[0]}
                                    </span>
                                )}
                            </form>
                        ) : (
                            <>
                                <button
                                    className="text-[11px] hover:text-iknow-yellow"
                                    onClick={() => setOpenChangePassword(true)}
                                >
                                    CHANGE PASSWORD
                                </button>
                            </>
                        )}
                        <br />
                    </div>
                </div>
            )}
            <div className="w-full p-[25px] md:p-[60px] bg-slate-900 rounded-[15px] text-white text-[14px]">
                <p className="font-bold text-[20px] text-center mb-[50px]">
                    MY SUBSCRIPTIONS
                </p>
                {subscriptions && subscriptions?.length > 0 ? (
                    <div className="w-full">
                        <table className="w-full border-separate border-spacing-2">
                            <thead>
                                <tr>
                                    <th className="bg-slate-400 py-[15px]">
                                        COURSE NAME
                                    </th>
                                    <th className="bg-slate-400">
                                        DATE SUBSCRIBED
                                    </th>
                                    <th className="bg-slate-400">
                                        SUBSCRIPTION EXPIRATION
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {_.map(subscriptions, (course) => {
                                    return (
                                        <tr className="text-center">
                                            <td className="py-[15px] font-bold">
                                                {course.courseName}
                                            </td>
                                            <td>
                                                {moment(
                                                    course.subscriptionDate
                                                ).format("DD MMMM YYYY")}
                                            </td>
                                            <td>
                                                {moment(
                                                    course.expirationDate
                                                ).format("DD MMMM YYYY")}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div className="text-center">
                        You are not subscribed to any courses.
                    </div>
                )}
            </div>
        </section>
    );
};

export default MyAccount;
