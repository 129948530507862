import axios, { AxiosInstance } from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { API_URL } from 'common/constants';
import { getToken, removeToken, setToken } from 'common/helpers';
import { browserHistory } from './history';
import { toast } from 'react-toastify';

const refreshTokenLogic = (failedRequest: any) => {
  return axios
    .post(`${API_URL}/auth/refresh-token`, { token: getToken() })
    .then((response) => {
      setToken(response.data.accessToken);
      failedRequest.response.config.headers.Authorization =
        'Bearer ' + response.data.token;
      return Promise.resolve();
    })
    .catch((err) => {
      if (err.response.status === 401 || err.response.status === 403) {
        handleExpiredToken();
      }
    });
};

const api = applyCaseMiddleware(
  axios.create({
    baseURL: API_URL,
  }),
);

const handleExpiredToken = () => {
  removeToken();
  browserHistory.push('/');
};

export const authenticatedClient = (): AxiosInstance => {
  const api = applyCaseMiddleware(
    axios.create({
      baseURL: API_URL,
      timeout: 60000,
    }),
  );

  api.interceptors.request.use(
    async (config) => {
      config.headers = {
        Authorization: `Bearer ${getToken()}`,
      };
      return config;
    },
    (error) => Promise.reject(error),
  );

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error.response) {
        const { status, data } = error.response;
        const isTokenExpired =
          data?.error?.message === 'Token has expired' ||
          data?.error?.message === 'Token Signature could not be verified.' ||
          data?.error?.message === 'The token has been blacklisted';
        
        if (status === 403 || status === 401) {
          createAuthRefreshInterceptor(api, refreshTokenLogic, {
            statusCodes: [401, 403],
          });
        } else if (status === 500) {
                    
          if (isTokenExpired) {
            handleExpiredToken();
          }
        } else if (status === 404) {
          // browserHistory.push('/404');
        }
      } else {
        toast.error('Something went wrong.');
      }

      return Promise.reject(error);
    },
  );

  createAuthRefreshInterceptor(api, refreshTokenLogic, {
    statusCodes: [401, 403],
  });

  return api;
};

export default api;