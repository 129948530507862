import React from "react";

import { IMAGE_URL } from "common/constants";
import Ripped from "assets/images/hero/ripped.png";
import LogoRain from "assets/images/hero/logo_rain.png";

const Hero = () => {
    return (
        <section className="relative w-full h-[85vh] bg-iknow-blue text-white">
            <div
                className="absolute w-full h-full section-parallax"
                style={{ backfaceVisibility: "hidden" }}
            >
                <div
                    className="absolute h-[105%] lg:h-[130%] bg-no-repeat bg-center bg-fixed bg-cover w-full"
                    style={{ backgroundImage: `url(${IMAGE_URL}${LogoRain})` }}
                ></div>

                <div className="parallax-element absolute top-[15%] lg:top-[30%] lg:left-[100px] h-full w-full lg:w-max px-[30px] lg:px-0">
                    <div className="w-full lg:w-max h-max">
                        <div className="">
                            <p className="text-[8vw] lg:text-[72px] font-semibold font-roboto lg:leading-[80px]">
                                I came. I saw.
                                <br />I conquered through{" "}
                                <br className="block lg:hidden" />
                                <span className="font-bold font-signika text-iknow-yellow text-[16vw] lg:text-[90px]">
                                    iKnow
                                </span>
                                .
                            </p>
                            <p className="text-[12px] lg:text-[24px] font-bold font-roboto lg:!mt-[20px] w-full">
                                ON-DEMAND COLLEGE ENTRANCE TEST (CET){" "}
                                <br className="block lg:hidden" />
                                ONLINE REVIEWER
                            </p>
                        </div>
                        <div className="w-[70%] pt-[30px] lg:!pt-[50px] mx-auto lg:mx-0">
                            <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-[40px]">
                                <div className="w-full">
                                    <a href="https://iknowacadhub.thinkific.com/">
                                        <button className="py-[5px] lg:py-[15px] rounded-full font-bold font-roboto bg-iknow-yellow text-iknow-blue hover:bg-iknow-blue hover:text-iknow-yellow border-2 duration-500 transition-all border-iknow-yellow w-full">
                                            START COURSE
                                        </button>
                                    </a>
                                </div>
                                <div className="w-full">
                                    <a href="/?ref=why">
                                        <button className="py-[5px] lg:py-[15px] rounded-full font-bold font-roboto bg-iknow-yellow text-iknow-blue hover:bg-iknow-blue hover:text-iknow-yellow border-2 duration-500 transition-all border-iknow-yellow w-full">
                                            KNOW MORE
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full absolute bottom-[-1px]">
                    <img src={Ripped} alt="" className="w-full" />
                </div>
            </div>
        </section>
    );
};

export default Hero;
