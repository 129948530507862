import {
  selectAllActiveTopics,
} from "modules/topics/slice";
import useAppSelector from "./useAppSelector";

const useTopics = () => {
  const activeTopics = useAppSelector(selectAllActiveTopics);

  return{
    activeTopics
  }
}

export default useTopics;