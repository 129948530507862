import { ILinks } from "common/types";

export const headerLinks: ILinks[] = [
    {
        link: "/about-us",
        displayText: "About Us",
        hidden: false,
    },
    {
        link: "/pricing",
        displayText: "Pricing",
        hidden: true,
    },
    {
        link: "/?ref=testimonials",
        displayText: "Testimonials",
        hidden: false,
    },
    {
        link: "/announcements",
        displayText: "Announcements",
        hidden: false,
    },
    // {
    // 	link: "https://iknowacadhub.thinkific.com/",
    // 	displayText: "Sign In",
    // 	hidden: false,
    // },
    {
        link: "/register",
        displayText: "Register",
        hidden: false,
    },
];

export const socialLinks: ILinks[] = [
    {
        link: "https://www.youtube.com/@iKnowAHOfficial",
        displayText: "iKnow Academic Hub Youtube Channel",
        hidden: false,
    },
    {
        link: "https://www.instagram.com/iknowacademichub.official/",
        displayText: "iKnow Academic Hub Instagram",
        hidden: false,
    },
    {
        link: "https://www.tiktok.com/@iknowacademichub",
        displayText: "iKnow Academic Hub Tiktok",
        hidden: false,
    },
    {
        link: "https://www.facebook.com/iknowacademichub.official",
        displayText: "iKnow Academic Hub Official Facebook Page",
        hidden: false,
    },
    {
        link: "https://www.facebook.com/groups/mathmadesimplebysirmon",
        displayText: "Math Made Simple Facebok Group",
        hidden: false,
    },
];

export const contactInformation: ILinks[] = [
    {
        link: "tel:+639610273376",
        displayText: "+63 961 027 3376",
        hidden: false,
    },
    {
        link: "mailto:support@iknowacademichub.com",
        displayText: "support@iknowacademichub.com",
        hidden: false,
    },
];
