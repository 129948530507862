import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import { ICourse, RequestStatusEnum } from 'common/types';


type InitialState = {
  allCourses: ICourse[] | null;
  allCoursesStatus: RequestStatusEnum | null;
  allActiveCourses: ICourse[] | null;
  allActiveCoursesStatus: RequestStatusEnum | null;
  selectedCourse: ICourse | null;
  selectedCourseStatus: RequestStatusEnum | null;
  subscribedCourses: ICourse | null;
  subscribedCoursesStatus: RequestStatusEnum | null;
}

const initialState: InitialState = {
  allCourses: null,
  allCoursesStatus: null,
  allActiveCourses: null,
  allActiveCoursesStatus: null,
  selectedCourse: null,
  selectedCourseStatus: null,
  subscribedCourses: null,
  subscribedCoursesStatus: null,
}

export const slice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    reset: () => initialState,
    setAllCourses: (state, action: PayloadAction<ICourse[] | null>) => {
      state.allCourses = action.payload;
    },
    setAllCoursesStatus: (state, action: PayloadAction<RequestStatusEnum>) => {
      state.allCoursesStatus = action.payload;
    },
    setAllActiveCourses: (state, action: PayloadAction<ICourse[] | null>) => {
      state.allActiveCourses = action.payload;
    },
    setAllActiveCoursesStatus: (state, action: PayloadAction<RequestStatusEnum>) => {
      state.allActiveCoursesStatus = action.payload;
    },
    setSelectedCourse: (state, action: PayloadAction<ICourse | null>) => {
      state.selectedCourse = action.payload;
    },
    setSelectedCourseStatus: (state, action: PayloadAction<RequestStatusEnum>) => {
      state.selectedCourseStatus = action.payload;
    },
    setSubscribedCourses: (state, action: PayloadAction<ICourse | null>) => {
      state.subscribedCourses = action.payload;
    },
    setSubscribedCoursesStatus: (state, action: PayloadAction<RequestStatusEnum>) => {
      state.subscribedCoursesStatus = action.payload;
    },
  }
});

export const {
  reset,
  setAllCourses,
  setAllCoursesStatus,
  setAllActiveCourses,
  setAllActiveCoursesStatus,
  setSelectedCourse,
  setSelectedCourseStatus,
  setSubscribedCourses,
  setSubscribedCoursesStatus,
} = slice.actions;

export const selectAllCourses = (state: RootState): ICourse[] | null =>
  state.courses.allCourses;
  export const selectAllCoursesStatus = (state: RootState): RequestStatusEnum | null =>
  state.courses.allCoursesStatus;
export const selectAllActiveCourses = (state: RootState): ICourse[] | null =>
  state.courses.allActiveCourses;
export const selectAllActiveCoursesStatus = (state: RootState): RequestStatusEnum | null =>
  state.courses.allActiveCoursesStatus;
export const selectSelectedCourse = (state: RootState): ICourse | null =>
  state.courses.selectedCourse;
export const selectSelectedCourseStatus = (state: RootState): RequestStatusEnum | null =>
  state.courses.selectedCourseStatus;
export const selectSubscribedCourses = (state: RootState): ICourse | null =>
  state.courses.subscribedCourses;
export const selectSubscribedCoursesStatus = (state: RootState): RequestStatusEnum | null =>
  state.courses.subscribedCoursesStatus;

export default slice.reducer;