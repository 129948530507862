import React from "react";
import PublicPageLayout from "components/layouts/PublicPageLayout";
import { Helmet } from "react-helmet";
import Hero from "components/Hero";
import Studying from "assets/images/hero/studying.png";

const AboutUs = () => {
  return(
    <PublicPageLayout>
      <Helmet>
        <title>iKnow Academic Hub: About Us</title>
        <meta name="About Us" content="Welcome to iKnow Academic Hub!" />
      </Helmet>
      <Hero title="About Us" image={Studying} />
      <section className="px-[30px] max-w-[800px]">
        Welcome to <strong>iKnow Academic Hub</strong>. We primarily offer review courses for college entrance tests. We understand that preparing for college entrance exams can be a challenging and crucial task. That&apos;s why we have designed this comprehensive review course to help you succeed and achieve your academic goals.
        <br/><br/>
        Our online review course is specifically tailored to <strong>provide you with the knowledge, skills, and strategies necessary</strong> to excel in various college entrance tests, such as the UPCAT, ACET, DCAT, USTET or any other college admission tests required by universities within the Philippines. Whether you&apos;re a high school student aiming for top-tier institutions or a non-traditional learner pursuing higher education, our course is designed to meet your specific needs.
        <br/><br/>
        What sets our online review course apart is our commitment to providing a flexible and convenient learning experience. With our user-friendly platform, you can access course materials anytime, anywhere, allowing you to study at your own pace and according to your schedule. We understand that students have diverse learning preferences, so we offer a variety of engaging resources, including video lectures, interactive practice quizzes, comprehensive study guides, and mock exams, to cater to different learning styles. We also offer limited slots of specialized review courses with scheduled online classes for those who wanted in-depth instructionsal methods with our instructor.
        <br/><br/>
        Course curricula are carefully crafted to cover all key concepts and test-taking strategies, ensuring that you have a solid foundation in the subjects tested. Moreover, the team of iKnow Academic Hub is readily available to provide guidance and support throughout your learning journey, addressing any questions or concerns you may have.
        <br/><br/>
        In addition to the core content, our online review course also focuses on developing essential skills, such as <strong>critical thinking, problem-solving, and time management</strong>. These skills are vital not only for the exams but also for your future academic endeavors. We believe in <strong>empowering you with the tools necessary to approach questions with confidence and maximize your performance</strong> on test day.
        <br/><br/>
        Furthermore, our course keeps up with the latest trends and updates in college entrance exams, ensuring that you receive the most up-to-date information and strategies. We regularly review and update our materials to align with any changes made to the exams, allowing you to stay ahead of the curve and adapt to evolving test formats.
        <br/><br/>
        By enrolling in our online review course, you gain access to a supportive learning community of fellow students who are on the same journey as you. Collaborate, share experiences, and engage in discussions through our dedicated online forums and chat groups. This interactive environment fosters peer-to-peer learning and provides an opportunity to exchange tips, insights, and encouragement.
        <br/><br/>
        Ultimately, our goal is to help you <strong>unlock your full potential and achieve your desired scores on college entrance tests</strong>. We believe that with the right guidance, preparation, and determination, you can secure admission to the college or university of your dreams. Join our online review course today and embark on a path to success!
      </section>
    </PublicPageLayout>
  )
}

export default AboutUs;