import { SAGA_ACTIONS as AUTH_ACTIONS } from 'modules/auth/sagas';
import { SAGA_ACTIONS as COURSES_ACTIONS } from 'modules/courses/sagas';
import { SAGA_ACTIONS as LESSONS_ACTIONS } from 'modules/lessons/sagas';
import { SAGA_ACTIONS as ME_ACTIONS } from 'modules/me/sagas';
import { SAGA_ACTIONS as TOPICS_ACTIONS } from 'modules/topics/sagas';

const ACTIONS = {
  ...AUTH_ACTIONS,
  ...COURSES_ACTIONS,
  ...LESSONS_ACTIONS,
  ...ME_ACTIONS,
  ...TOPICS_ACTIONS,
};

export default ACTIONS;
