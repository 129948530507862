import { takeLatest } from 'redux-saga/effects';
import {
  SAGA_ACTIONS,
  fetchAllActiveTopics,
  fetchAllTopics,
  fetchTopicDetails,
} from './sagas';

const {
  FETCH_ALL_ACTIVE_TOPICS,
  FETCH_ALL_TOPICS,
  FETCH_TOPIC_DETAILS
} = SAGA_ACTIONS;

const actions = [
  takeLatest(FETCH_ALL_ACTIVE_TOPICS, fetchAllActiveTopics),
  takeLatest(FETCH_ALL_TOPICS, fetchAllTopics),
  takeLatest(FETCH_TOPIC_DETAILS, fetchTopicDetails),
];

export default actions;
