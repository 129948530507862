import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import auth from 'modules/auth/slice';
import courses from 'modules/courses/slice';
import lessons from 'modules/lessons/slice';
import me from 'modules/me/slice';
import topics from 'modules/topics/slice';
import { browserHistory } from './history';

export default combineReducers({
  auth,
  courses,
  lessons,
  me,
  topics,
  router: connectRouter(browserHistory),
});
