import axios from "axios";
import { call, put } from "redux-saga/effects";
import { RequestStatusEnum } from "common/types";
import { handleAPIError } from "common/errorHandler";
import { authenticatedClient } from "app/apiClient";
import {
    setChangeStatus,
    setSubscriptions,
    setUser,
    setUserStatus,
} from "./slice";
import { PayloadAction } from "@reduxjs/toolkit";
import { setError } from "modules/auth/slice";

export const SAGA_ACTIONS = {
    FETCH_USER_INFO: "FETCH_USER_INFO",
    FETCH_USER_SUBSCRIPTIONS: "FETCH_USER_SUBSCRIPTIONS",
    UPDATE_USER_PASSWORD: "UPDATE_USER_PASSWORD",
};

type FetchUserInfoPayload = {
    userId: number;
};

export function* fetchUserInfo(
    action: PayloadAction<FetchUserInfoPayload>
): any {
    const api = authenticatedClient();
    try {
        yield put(setUserStatus(RequestStatusEnum.PENDING));
        const { data } = yield call(() =>
            api.post("/users/details", action.payload)
        );
        yield put(setUser(data[0]));
        yield put(setUserStatus(RequestStatusEnum.SUCCESS));
    } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
            handleAPIError(err.response);
            yield put(setUserStatus(RequestStatusEnum.FAILED));
        }
    }
}

type FetchUserSubscriptionsPayload = {
    userId: number;
};

export function* fetchUserSubscriptions(
    action: PayloadAction<FetchUserSubscriptionsPayload>
): any {
    const api = authenticatedClient();
    try {
        const { data } = yield call(() =>
            api.post("/users/subscriptions", action.payload)
        );
        yield put(setSubscriptions(data));
    } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
            handleAPIError(err.response);
        }
    }
}

type UpdateUserPasswordPayload = {
    password: string;
};

export function* updateUserPassword(
    action: PayloadAction<UpdateUserPasswordPayload>
): any {
    const api = authenticatedClient();
    try {
        yield put(setChangeStatus(RequestStatusEnum.PENDING));
        yield call(() => api.post("/users/change-password", action.payload));
        yield put(setChangeStatus(RequestStatusEnum.SUCCESS));
    } catch (err) {
        yield put(setChangeStatus(RequestStatusEnum.ERROR));
        if (axios.isAxiosError(err) && err.response) {
            handleAPIError(err.response);
            if (err.response.data?.errors) {
                yield put(setError(err.response.data?.errors));
            }
        }
    }
}
