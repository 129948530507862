import axios from 'axios';
// import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';
import { RequestStatusEnum } from 'common/types';
import { handleAPIError } from 'common/errorHandler';
import api from 'app/apiClient';
import {
  setAllCourses,
  setAllActiveCourses,
  setSelectedCourse,
  setAllActiveCoursesStatus,
  setAllCoursesStatus,
  setSelectedCourseStatus
} from './slice';
import { PayloadAction } from '@reduxjs/toolkit';

export const SAGA_ACTIONS = {
  FETCH_ALL_COURSES: 'FETCH_ALL_COURSES',
  FETCH_ALL_ACTIVE_COURSES: 'FETCH_ALL_ACTIVE_COURSES',
  FETCH_COURSE_DETAILS: 'FETCH_COURSE_DETAILS'
};

// type FetchAllActiveCoursesPayload = {

// }

export function* fetchAllActiveCourses(
  // action: PayloadAction<FetchAllActiveCoursesPayload>,
): any {
  const payload = {

  }

  try {
    yield put(setAllActiveCoursesStatus(RequestStatusEnum.PENDING));
    
    const { data } = yield call(() =>
      api.post('/courses', payload)
    );
    yield put(setAllActiveCourses(data));
    yield put(setAllActiveCoursesStatus(RequestStatusEnum.SUCCESS))
  } catch (err){
    if(axios.isAxiosError(err) && err.response){
      handleAPIError(err.response);
      yield put(setAllActiveCoursesStatus(RequestStatusEnum.FAILED));
    }
  }
}

// type FetchAllCoursesPayload = {
//   // verified Admin
// }

export function* fetchAllCourses(
  // action: PayloadAction<FetchAllCoursesPayload>,
): any {
  try {
    yield put(setAllCoursesStatus(RequestStatusEnum.PENDING));
    
    const { data } = yield call(() =>
      api.post('/courses/all')
    );
    yield put(setAllCourses(data));
    yield put(setAllCoursesStatus(RequestStatusEnum.SUCCESS))
  } catch (err){
    if(axios.isAxiosError(err) && err.response){
      handleAPIError(err.response);
      yield put(setAllCoursesStatus(RequestStatusEnum.FAILED));
    }
  }
}

type FetchCourseDetailsPayload = {
  courseId: number[]
}

export function* fetchCourseDetails(
  action: PayloadAction<FetchCourseDetailsPayload>,
): any {

  try {
    yield put(setSelectedCourseStatus(RequestStatusEnum.PENDING));
    
    const { data } = yield call(() =>
      api.post('/course', action.payload)
    );
    yield put(setSelectedCourse(data));
    yield put(setSelectedCourseStatus(RequestStatusEnum.SUCCESS))
  } catch (err){
    if(axios.isAxiosError(err) && err.response){
      handleAPIError(err.response);
      yield put(setSelectedCourseStatus(RequestStatusEnum.FAILED));
    }
  }
}