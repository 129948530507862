export const faqs = [
  {
    q: 'Why review online?',
    a: ['Online courses have been on the rise in the recent years. They have become hugely popular by the simple virtue of being so much more convenient than traditional face-to-face classes.']
  },
  {
    q: 'How can you say that reviewing online is more effective?',
    a: ['Online courses are more effective for the following reasons:',
        'a) Students learn more than they do in traditional classes.',
        'b) Retention rates are higher with online courses.',
        'c) Online courses require less of a time investment.',
        'd) More frequent assessments, practice quizzes, and challenge exams and simulations prepare students for the actual exam.']
  },
  {
    q: 'Is the review also effective for other CETs?',
    a: ['YES. The courses offered here at iKnow are patterned most after the UPCAT, ACET, DCAT, and USTET. Moreover, they are also applicable to other CETs like SAT, ACT, and scholarship exams like DOST-SEI.']
  },
  {
    q: 'I am the top student of the batch, do I still need to review?',
    a: ['If you already expect to pass the CETs, then you are wrong. Remember that other examinees are preparing even harder to make sure they get to make it to their dream university. Your next goal, at least, should be to top the entrance exams.']
  },
  {
    q: 'What else does iKnow offer?',
    a: ['iKnow courses include a chat support whenever you need assistance on the online course. Assessments are available at the end of each lesson and simulated examinations are also available a month before the scheduled CET.']
  },
]