import React from "react";
import useTopics from "hooks/useTopics";
import * as _ from "lodash";
import { ITopic } from "common/types";
import { useEffectOnce } from "react-use";
import { Link, useParams } from "react-router-dom";
import ACTIONS from "modules/rootActions";
import useAppDispatch from "hooks/useAppDispatch";

interface ParamTypes {
    courseId: string;
}

const Topics = () => {
    const dispatch = useAppDispatch();
    const { courseId } = useParams<ParamTypes>();

    const { activeTopics } = useTopics();

    useEffectOnce(() => {
        dispatch({
            type: ACTIONS.FETCH_ALL_ACTIVE_TOPICS,
            payload: { courseId },
        });
    });

    return (
        <section>
            <p className="mx-auto text-center font-signika font-bold text-iknow-blue text-[26px] md:text-[36px] lg:text-[72px] mb-[40px]">
                TOPICS
            </p>
            <div className="mx-[25px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[30px]">
                {_.map(activeTopics, (topic: ITopic) => {
                    return (
                        <Link
                            key={topic.id}
                            to={`/app/topics/${topic.id}`}
                            className="font-roboto text-white bg-slate-500 hover:bg-yellow-600 rounded-[25px] p-[25px] md:p-[45px]"
                        >
                            <p className="font-bold text-[16px] md:text-[22px] mb-[15px]">
                                {topic.topicName}
                            </p>
                            <p className="hidden sm:block sm:text-[12px] md:text-[14px] mb-[20px]">
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: topic.topicDescription,
                                    }}
                                />
                            </p>
                            <div className="flex gap-[10px] font-bold text-[10px]">
                                <p className="bg-slate-900 rounded-full w-fit py-[5px] px-[10px]">
                                    ## Lessons
                                </p>
                                <p className="bg-slate-900 rounded-full w-fit py-[5px] px-[10px]">
                                    n Hours n Minutes
                                </p>
                            </div>
                        </Link>
                    );
                })}
            </div>
        </section>
    );
};

export default Topics;
