import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import { ILesson, RequestStatusEnum } from 'common/types';


type InitialState = {
  allLessons: ILesson[] | null;
  allLessonsStatus: RequestStatusEnum | null;
  allActiveLessons: ILesson[] | null;
  allActiveLessonsStatus: RequestStatusEnum | null;
  selectedLesson: ILesson | null;
  selectedLessonStatus: RequestStatusEnum | null;
}

const initialState: InitialState = {
  allLessons: null,
  allLessonsStatus: null,
  allActiveLessons: null,
  allActiveLessonsStatus: null,
  selectedLesson: null,
  selectedLessonStatus: null,
}

export const slice = createSlice({
  name: 'lessons',
  initialState,
  reducers: {
    reset: () => initialState,
    setAllLessons: (state, action: PayloadAction<ILesson[] | null>) => {
      state.allLessons = action.payload;
    },
    setAllLessonsStatus: (state, action: PayloadAction<RequestStatusEnum>) => {
      state.allLessonsStatus = action.payload;
    },
    setAllActiveLessons: (state, action: PayloadAction<ILesson[] | null>) => {
      state.allActiveLessons = action.payload;
    },
    setAllActiveLessonsStatus: (state, action: PayloadAction<RequestStatusEnum>) => {
      state.allActiveLessonsStatus = action.payload;
    },
    setSelectedLesson: (state, action: PayloadAction<ILesson | null>) => {
      state.selectedLesson = action.payload;
    },
    setSelectedLessonStatus: (state, action: PayloadAction<RequestStatusEnum>) => {
      state.selectedLessonStatus = action.payload;
    },
  }
});

export const {
  reset,
  setAllLessons,
  setAllLessonsStatus,
  setAllActiveLessons,
  setAllActiveLessonsStatus,
  setSelectedLesson,
  setSelectedLessonStatus,
} = slice.actions;

export const selectAllLessons = (state: RootState): ILesson[] | null =>
  state.lessons.allLessons;
  export const selectAllLessonsStatus = (state: RootState): RequestStatusEnum | null =>
  state.lessons.allLessonsStatus;
export const selectAllActiveLessons = (state: RootState): ILesson[] | null =>
  state.lessons.allActiveLessons;
export const selectAllActiveLessonsStatus = (state: RootState): RequestStatusEnum | null =>
  state.lessons.allActiveLessonsStatus;
export const selectSelectedLesson = (state: RootState): ILesson | null =>
  state.lessons.selectedLesson;
export const selectSelectedLessonStatus = (state: RootState): RequestStatusEnum | null =>
  state.lessons.selectedLessonStatus;

export default slice.reducer;