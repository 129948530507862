import React from "react";

import { Title } from "components/Typography";
import { whyIKnow } from "helpers/why";
import Item from "./Why/Item";

const Why = () => {
    return (
        <section
            id="why"
            className="bg-iknow-lblue-1 !px-[50px] lg:!px-[100px] !py-[80px]"
        >
            <div className="text-iknow-blue w-full">
                <Title>Why iKnow?</Title>
                <div className="flex flex-col w-full lg:w-max !mx-auto lg:!mt-[50px] text-center lg:text-left gap-[30px] md:gap-[60px]">
                    {whyIKnow.map((item, index) => {
                        return <Item key={index} item={item} index={index} />;
                    })}
                    <button className="!px-[60px] !py-[15px] rounded-full font-bold font-roboto bg-iknow-blue text-white hover:bg-iknow-yellow hover:text-iknow-blue duration-500 md:w-[300px] mx-auto">
                        START COURSE
                    </button>
                </div>
            </div>
        </section>
    );
};

export default Why;
