import React, { ReactNode } from "react";

export const Title = ({ children }: { children: ReactNode }) => {
    return (
        <p className="mt-6 text-center text-[8vw] md:text-[52px] font-roboto-slab uppercase leading-[25px] mb-8 md:mb-16">
            {children}
        </p>
    );
};

export const InvalidText = ({ children }: { children: ReactNode }) => {
    return (
        <p className="ml-1 mb-4 font-bold text-[12px] text-red-800 self-start">
            {children}
        </p>
    );
};
