import React, { useState } from "react";
import * as _ from "lodash";
import { useEffectOnce } from "react-use";
import { useParams } from "react-router-dom";
import ACTIONS from "modules/rootActions";
import useAppDispatch from "hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";
import { selectSelectedTopic } from "modules/topics/slice";
import { selectAllActiveLessons } from "modules/lessons/slice";

interface ParamTypes {
  topicId: string;
}

const Lessons = () => {
  const dispatch = useAppDispatch();
  const topic = useAppSelector(selectSelectedTopic);
  const lessons = useAppSelector(selectAllActiveLessons);
  const {topicId} = useParams<ParamTypes>();
  const [lesson, setLesson] = useState<number>(1)
  
  useEffectOnce(() => {
    dispatch({ type: ACTIONS.FETCH_TOPIC_DETAILS,
      payload:{ topicId }
    });
    dispatch({ type: ACTIONS.FETCH_ALL_ACTIVE_LESSONS,
      payload:{ topicId }
    });
  });

  return (
    <section className="flex flex-col md:flex-row">
      <div className="basis-1/3 px-[25px] flex flex-col order-2 md:order-1">
        <div className="pb-[15px]">
          <p className="font-bold text-[16px] md:text-[20px]">
            {topic?.topicName}
          </p>
          <p className="hidden md:block text-[10px] md:text-[14px] leading-[13px] md:leading-[16px]">
            {topic?.topicDescription}
          </p>
          { lessons && lessons?.length > 0 &&
            <p className="font-bold text-[11px] pt-[20px]">
              {lessons?.length} LESSONS
            </p>
          }
        </div>
        <div className="max-h-full overflow-y-auto grid grid-cols-1 gap-y-[10px]">
          {_.map(lessons, (lesson, index) => {
            return (
              <div key={lesson.id} onClick={() => setLesson(index)} className="h-full flex flex-row p-[15px] bg-slate-700 hover:bg-slate-900 rounded-[15px] text-white">
                <div className="my-auto w-[25px] bg-white text-center font-bold text-slate-700 rounded-full mr-[25px]">
                  {index + 1}
                </div>
                <div className="h-full">
                  <p className="font-bold text-[14px]">
                    {lesson.lessonName}
                  </p>
                  <p className="text-[10px] text-slate-400">
                    NN hours, XX minutes
                  </p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="w-full p-[25px] md:py-0 md:px-[25px] order-1 md:order-2">
        { lessons !== null && lessons.length > 0 &&
          <div>
            <div className="w-full min-h-[300px] m-auto text-center">
              {lessons[lesson].videoLink !==null ?
                <iframe
                  // width="853"
                  // height="480"
                  src={`https://www.youtube.com/embed/${lessons[lesson].videoLink}`}
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                  className="w-full min-h-[480px] md:min-h-[600px] bg-black"
                />
                :
                <>No video available for this lesson</>
              }
            </div>
            <div className="pt-[15px]">
              <p className="font-bold uppercase">
                {lessons[lesson].lessonName}
              </p>
              <p className="text-[11px] md:text-[16px]">
                {lessons[lesson].lessonDescription}
              </p>
            </div>
          </div>
        }
      </div>
    </section>
  )
}

export default Lessons;