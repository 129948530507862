import React, { ReactNode, useEffect } from "react";

import { PublicNavigation } from "components/Navigation/PublicNavigation";
import { selectIsLoginModalOpen, setLoginError } from "modules/auth/slice";
import useAppSelector from "hooks/useAppSelector";
import LoginModal from "components/LoginModal";
import Footer from "components/Footer";

interface Props {
    children: ReactNode;
}

const PublicPageLayout = ({ children }: Props) => {
    const isLoginModalOpen = useAppSelector(selectIsLoginModalOpen);

    useEffect(() => {
        if (!isLoginModalOpen) setLoginError(null);
    }, [isLoginModalOpen]);

    return (
        <>
            {isLoginModalOpen && <LoginModal />}
            <PublicNavigation />
            <main className="pb-[30px] lg:pb-[110px] flex items-center flex-col self-center bg-iknow-lblue-1">
                {children}
            </main>
            <Footer />
        </>
    );
};

export default PublicPageLayout;
