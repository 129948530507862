import { all } from 'redux-saga/effects';
import authWatcher from 'modules/auth/watcher';
import courseWatcher from 'modules/courses/watcher';
import lessonsWatcher from 'modules/lessons/watcher';
import meWatcher from 'modules/me/watcher';
import topicWatcher from 'modules/topics/watcher';

export default function* rootSaga() {
  yield all([...authWatcher]);
  yield all([...courseWatcher]);
  yield all([...lessonsWatcher]);
  yield all([...meWatcher]);
  yield all([...topicWatcher]);
}
