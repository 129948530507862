import axios from 'axios';
// import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';
import { RequestStatusEnum } from 'common/types';
import { handleAPIError } from 'common/errorHandler';
import { authenticatedClient } from 'app/apiClient';
import {
  setAllTopics,
  setAllActiveTopics,
  setSelectedTopic,
  setAllActiveTopicsStatus,
  setAllTopicsStatus,
  setSelectedTopicStatus
} from './slice';
import { PayloadAction } from '@reduxjs/toolkit';

export const SAGA_ACTIONS = {
  FETCH_ALL_TOPICS: 'FETCH_ALL_TOPICS',
  FETCH_ALL_ACTIVE_TOPICS: 'FETCH_ALL_ACTIVE_TOPICS',
  FETCH_TOPIC_DETAILS: 'FETCH_TOPIC_DETAILS'
};

type FetchAllActiveTopicsPayload = {
  courseId: number;
}

export function* fetchAllActiveTopics(
  action: PayloadAction<FetchAllActiveTopicsPayload>,
): any {
  const api = authenticatedClient();

  try {
    yield put(setAllActiveTopicsStatus(RequestStatusEnum.PENDING));
    
    const { data } = yield call(() =>
    api.post('/topics', action.payload)
    );
    yield put(setAllActiveTopics(data));
    yield put(setAllActiveTopicsStatus(RequestStatusEnum.SUCCESS))
  } catch (err){
    if(axios.isAxiosError(err) && err.response){
      handleAPIError(err.response);
      yield put(setAllActiveTopicsStatus(RequestStatusEnum.FAILED));
    }
  }
}

// type FetchAllTopicsPayload = {
//   // verified Admin
// }

export function* fetchAllTopics(
  // action: PayloadAction<FetchAllTopicsPayload>,
): any {
  const api = authenticatedClient();
  const payload = {

  }

  try {
    yield put(setAllTopicsStatus(RequestStatusEnum.PENDING));
    
    const { data } = yield call(() =>
      api.post('/topics/all', payload)
    );
    yield put(setAllTopics(data));
    yield put(setAllTopicsStatus(RequestStatusEnum.SUCCESS))
  } catch (err){
    if(axios.isAxiosError(err) && err.response){
      handleAPIError(err.response);
      yield put(setAllTopicsStatus(RequestStatusEnum.FAILED));
    }
  }
}

type FetchTopicDetailsPayload = {
  topicId: number;
}

export function* fetchTopicDetails(
  action: PayloadAction<FetchTopicDetailsPayload>,
): any {
  const api = authenticatedClient();

  try {
    yield put(setSelectedTopicStatus(RequestStatusEnum.PENDING));
    
    const { data } = yield call(() =>
      api.post('/topic', action.payload)
    );
    yield put(setSelectedTopic(data[0]));
    yield put(setSelectedTopicStatus(RequestStatusEnum.SUCCESS))
  } catch (err){
    if(axios.isAxiosError(err) && err.response){
      handleAPIError(err.response);
      yield put(setSelectedTopicStatus(RequestStatusEnum.FAILED));
    }
  }
}