import { takeLatest } from "redux-saga/effects";
import {
    SAGA_ACTIONS,
    fetchUserInfo,
    fetchUserSubscriptions,
    updateUserPassword,
} from "./sagas";

const { FETCH_USER_INFO, FETCH_USER_SUBSCRIPTIONS, UPDATE_USER_PASSWORD } =
    SAGA_ACTIONS;

const actions = [
    takeLatest(FETCH_USER_INFO, fetchUserInfo),
    takeLatest(FETCH_USER_SUBSCRIPTIONS, fetchUserSubscriptions),
    takeLatest(UPDATE_USER_PASSWORD, updateUserPassword),
];

export default actions;
