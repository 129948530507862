export const API_URL = process.env.REACT_APP_API_URL;
export const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

export const tips = [
  {
    id: 0,
    title: "Know the Exam",
    content: "TIn order to successfully overcome challenging exams such as UPCAT, ACET, DCAT, and others, it is essential to possess comprehensive knowledge about the exam itself. Familiarity with the structure of the examination, the format of the question paper, the syllabus, and the level of difficulty can greatly benefit you. This understanding will not only equip you for the upcoming test but also play a pivotal role in enabling you to develop effective strategies to tackle the exam with confidence.",
    image: "entrance_exam"
  },
  {
    id: 1,
    title: "Time Management is the Key",
    content: "Time and tide wait for no one, as the saying goes. Those who recognize the importance of time have a distinct advantage. Effective time management is crucial for enhancing productivity. It makes tasks appear less overwhelming and keeps you organized. Efficient time management boosts self-confidence, aids in setting priorities, and reduces stress levels. Moreover, it enables you to make optimal use of your time, allowing for leisure activities.",
    image: "calendar"
  },
  {
    id: 2,
    title: "Understand the Concepts",
    content: "To excel in exams, a strong command of the subject is vital. This requires a deep understanding of the theoretical aspects and thoroughness in knowledge. Additionally, emphasis should be placed on grasping the underlying concepts, as clarity in concepts facilitates easier learning and long-term retention of information. It is often said that having clear fundamentals and concepts gives you a significant advantage in the race. Therefore, it is advisable to prioritize understanding the concepts before delving into further learning.",
    image: "nitrogen_cycle"
  },
  {
    id: 3,
    title: "Inculcate Note Taking Habit",
    content: "An important habit to develop from day one is note-taking. This practice not only aids in comprehending lectures more effectively but also keeps your mind alert and attentive during class. Note-taking is a highly beneficial method to actively engage your mind. Moreover, creating your own notes while studying provides an additional boost to your learning process. These notes can be invaluable when it comes to the critical period before exams, as a quick review can help you recall what you've learned.",
    image: "notes"
  },
  {
    id: 4,
    title: "Study Every Day",
    content: "By studying daily and setting short-term goals, you can maintain a steady pace and stay organized with your lessons. This approach minimizes effort and prevents exam-related pressure. Conversely, procrastination adds to your workload, diminishing efficiency and increasing stress. It is advisable to reinforce what is taught in class by following up with home study to enhance the depth of learning.",
    image: "list"
  },
  {
    id: 5,
    title: "Know Your Weaknesses",
    content: "Identifying your weaknesses right in the beginning can help you know where you stand, what your abilities are, and how much effort you need to put in to reach the top. Your weaknesses are neither your downfall nor something that you lack, but what you need to develop and build. Knowing your pitfalls gives you an opportunity to work on them and improve your performance.",
    image: "stress"
  },
  {
    id: 6,
    title: "Never Give-Up",
    content: "True, life throws numerous challenges at you. But if you learn to cope up, no problem or situation is so big that you lose faith in your abilities and think of giving up. Also, you should keep in mind that within every struggle lays an opportunity to succeed. You should thus keep pushing yourself and keep trying until you succeed. Under no circumstances should you ever think of quitting. Learn to take every difficulty as an opportunity in disguise and always remember that nothing is impossible if you have the courage and willpower to do it.",
    image: "graduation"
  },
]